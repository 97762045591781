import React from 'react';
import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import Image from 'react-bootstrap/esm/Image';
import {Consent, IubendaPrivacy, IubendaCookie} from './Policy';
import 'bootstrap/dist/css/bootstrap.min.css';

function Footer() {
    return(
        <>
        <Consent/>
        <div className='Footer w-100'>
            <Container>
                <Row>
                    <Col style={{textAlign: 'center', margin:  'auto', marginTop: '1rem'}} md = "4">
                        <a alt="Banner di Beauty Hair di Giada Messina, a Palermo" href="/"><Image src="../beautyhairlogo.png" fluid rounded height="150" width="150"/></a>
                    </Col>
                    <Col style={{textAlign: 'center', margin:  'auto', marginTop: '1rem'}} md = "4">
                        <h1>Info</h1>
                        <p><a className='link-white' href="https://maps.app.goo.gl/feSkVHfBGAqFNGvs5">Via Giovanni Prati 32/34, Palermo, PA</a></p>
                        <p>Telefono: <a className='link-white' href="tel:393203229954">+39 320 322 99 54</a></p>
                        <p>Partita IVA: 07171400828</p>
                    </Col>
                    <Col style={{textAlign: 'center', margin:  'auto', marginTop: '1rem'}} md = "4">
                        <h1>Orari</h1>
                        <p>Lunedì-Venerdì: 08:00 - 18:00</p>
                        <p>Sabato: Chiusi</p>
                        <p>Domenica: Chiusi</p>
                    </Col>
                    <Col style={{marginTop: '1rem', fontSize: '10', textAlign: 'center', margin:  'auto'}}>
                        <p><b>Beauty Hair di Giada Messina - Palermo</b> Web App Professional sviluppata da <b><a className='link' href="realizzaonline.it">Realizza Online</a></b>.</p>
                        <p><IubendaPrivacy/><IubendaCookie/></p>
                    </Col>
                </Row>
            </Container>
        </div>
        </>
    );
}

export default Footer;