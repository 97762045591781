import { React, useEffect, useState } from 'react';
import {useLocation} from 'react-router-dom'
import MenuNav from './MenuNav';
import Footer from './Footer';
import axios from "axios";
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import { CardText, Calendar2Event, CheckLg, PhoneFill, EnvelopePlusFill, ListTask, Scissors, PencilFill } from 'react-bootstrap-icons';


function Result() {
    const location = useLocation()
    const queryParameters = new URLSearchParams(location.search)
    const [operatore, setOperatore] = useState([])
    const [pren, setPren] = useState([]);
    const getDay = (value) => {
        let val = "Errore";
        switch(value) {
            case 0: {
                val = "Domenica"
                break;
            }
            case 1: {
                val = "Lunedì"
                break;
            }
            case 2: {
                val = "Martedì"
                break;
            }
            case 3: {
                val = "Mercoledì"
                break;
            }
            case 4: {
                val = "Giovedì"
                break;
            }
            case 5: {
                val = "Venerdì"
                break;
            }
            case 6: {
                val = "Sabato"
                break;
            }
            default: {
                val = "Errore"
                break;
            }
        }
        return val;
    }
    useEffect(() => {
        const appointment = queryParameters.get("id")
        const key = queryParameters.get("key")
        if(appointment === null || isNaN(appointment) || key === null) {
            if (typeof window !== 'undefined') {
                window.location.href = "/";
            }
        }
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        const convalidaKey = async() => {
            const params = {
                id: appointment,
                chiave: key
            }
            axios.get('https://beautyhairpalermo.it:3001/convalidakey', {
                params
            })
            .then(res=> {
                if(!res.data.length) {
                    window.location.href = "/";
                } else {
                    setPren(res.data);
                }
            })
            .catch(error => console.log(error))
        }
        convalidaKey();
    },[]);
    useEffect(() => {
        const getOperatore = async() => {
            let oper = undefined;
            pren.map((item) => {
                return oper = item.Operatore;
            })
            const params = {
                id: oper
            }
            await axios.get('https://beautyhairpalermo.it:3001/getnomeoperatore', {
                params
            })
            .then(res=> setOperatore(res.data))
            .catch(error => console.log(error))
        }
        getOperatore();
    },[pren])

    return (
        <>
        <MenuNav active={{path: '/prenota'}}/>
            <div className="justify-content-center align-items-center" style={{minHeight: '150vh', background: '#000'}}>
                <h1 style={{textAlign: 'center'}}>Prenotazione inviata!</h1>
                <Col className="justify-content-center align-items-center">
                    <Card className="cardresult boxshadow">
                        <Card.Body>
                            {( () => {
                                const arr = [];
                                let nomeoper = "";
                                operatore.map((item2) => {
                                    return nomeoper = item2.Nome;
                                });
                                pren.map((item) => {
                                    arr.push(<Card.Title><CheckLg style={{color: '#0c507d'}}/> {item.Nome} {item.Cognome}</Card.Title>);
                                    arr.push(<Card.Subtitle><Calendar2Event/> <b>{getDay(item.Giorno)} {item.Data} {item.Ora}</b></Card.Subtitle>)
                                    arr.push(<Card.Text><CardText/>
                                        <p style={{color: 'black'}}><PhoneFill/> Cellulare: <b>{item.Cellulare}</b></p>
                                        <p style={{color: 'black'}}><EnvelopePlusFill/> Email: <b>{item.Email}</b></p>
                                        <p style={{color: 'black'}}><Scissors/> Operatore: <b>{nomeoper}</b></p>
                                        <p style={{color: 'black'}}><ListTask/> Servizi: <b>{item.Servizi}</b></p>
                                        <p style={{color: 'black'}}><PencilFill/> Note: <b>{item.Note}</b></p>
                                        <p style={{color: 'black'}}>La prenotazione sarà effettiva quando riceverai la <b>conferma</b> tramite messaggio WhatsApp o Email.</p>
                                    </Card.Text>);
                                    return true;
                                });
                                return arr;
                            })()}
                        </Card.Body>
                    </Card>
                </Col>
            </div>
        <Footer/>
        </>
    )
}
export default Result