import React, { useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Toast from 'react-bootstrap/Toast';

function Errore({error}) {
  const [show, setShow] = useState(true);
  console.log(error);
  const handleText = (error) => {
    if(error.id === 0) {
      return (<p>Il <b>nome</b> inserito non è valido.</p>);
    } else if(error.id === 1) {
      return (<p>Il <b>cognome</b> inserito non è valido.</p>);
    } else if(error.id === 2) {
      return (<p>Il <b>numero di cellulare</b> inserito non è valido.</p>);
    } else if(error.id === 3) {
      return (<p><b>L'email</b> inserita non è valida.</p>);
    } else if(error.id === 4) {
      return (<p>Devi accettare i <b>termini e le condizioni</b> per proseguire.</p>);
    } else if(error.id === 5) {
      return (<p>Devi inserire almeno un servizio per poter prenotare.</p>)
    } else if(error.id === 6) {
      return (<p>Il posto selezionato non è più disponibile.</p>)
    } else if(error.id === 7) {
      return (<p>Non è stata trovata nessuna prenotazione con i dati inseriti.</p>)
    } else if(error.id === 8) {
      return (<p>Devi inserire un giorno valido per poter disdire.</p>)
    } else if(error.id === 9) {
      return (<p>Devi inserire una data valida per poter disdire.</p>)
    } else if(error.id === 10) {
      return (<p>Devi inserire un orario valido per poter disdire.</p>)
    } else if(error.id === 11) {
      return (<p>La data e l'ora devono essere antecedenti alla data attuale per poter disdire.</p>)
    }
  }
  return error.id !== null ? (
      <Row>
        <Col>
          <Toast className="position-absolute" bg="danger" onClose={() => setShow(false)} show={show} delay={3000} autohide>
            <Toast.Header>
              <img
                src="holder.js/20x20?text=%20"
                className="rounded me-2"
                alt=""
              />
              <strong className="me-auto">Errore</strong>
              <small>2 secondi fa</small>
            </Toast.Header>
            <Toast.Body style={{color: '#fff'}}>
              {handleText(error)}
            </Toast.Body>
          </Toast>
        </Col>
      </Row>
  ) : <></>;
}
export default Errore;