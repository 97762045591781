import { React, useState, useEffect } from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Toast from 'react-bootstrap/Toast'
import ToastContainer from 'react-bootstrap/ToastContainer'
import Button from 'react-bootstrap/Button'
import Errore from './Errore';
import { CardChecklist, ArrowRight } from 'react-bootstrap-icons';

function Servizi({updateState, updateServizi, updatePrezzo, operatortype}) {
    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, []);
    const [error, showError] = useState(false);
    const [typerror, setError] = useState({
        id: null
    });
    const renderState = () => {
        updateServizi(stringafinale);
        updatePrezzo(prezzofinale);
        updateState();
    }
    let tecnici = [
        {id: 0, name:'Piega - 10,00€', selected: false, price: 10, durata: 3},
        {id: 1, name:'Taglio e piega - 20,00€', selected: false, price: 20, durata: 5},
        {id: 2, name:'Tonalizzante - 15,00€', selected: false, price: 15, durata: 4},
        {id: 3, name:'Colore e piega - 30,00€', selected: false, price: 30, durata: 7},
        {id: 4, name:'Colore, taglio e piega - 35,00€', selected: false, price: 35, durata: 8},    
        {id: 5, name:'Colore, mèches e piega - 50,00€', selected: false, price: 50, durata: 8},
        {id: 6, name:'Mèches e piega - 40,00€', selected: false, price:40, durata: 7},
        {id: 7, name:'Balayage e piega - 40,00€', selected: false, price: 40, durata: 10},
        {id: 8, name:'Sostegno riccio e piega - 60,00€', selected: false, price: 60, durata: 10},
        {id: 9, name:'Extension alla cheratina - 300,00€', selected: false, price: 300, durata: 4},
        {id: 10, name:'Trattamento cheratina lisciante - 80,00€', selected: false, price: 80, durata: 7},
        {id: 11, name:'Baffetti - 3,00€', selected: false, price: 3, durata: 1},
        {id: 12, name:'Sopracciglia - 3,00€', selected: false, price: 3, durata: 1},
        {id: 13, name:'Ceretta braccia - 3,00€', selected: false, price: 3, durata: 2},
        {id: 14, name:'Make up - da 30,00€', selected: false, price: 30, durata: 2}
    ];
    let trattamenti = [
        {id: 15, name:'Cheratina - da 5,00€', selected: false, price: 5, durata: 4},
        {id: 16, name:'Acido Ialuronico - da 5,00€', selected: false, price: 5, durata: 4},
        {id: 17, name:'Idratante - da 5,00€', selected: false, price: 5, durata: 4},
        {id: 18, name:'Anti crespo - da 5,00€', selected: false, price: 5, durata: 4},
        {id: 19, name:'Anti sebo - da 5,00€', selected: false, price: 5, durata: 4},
        {id: 20, name:'Anti forfora - da 5,00€', selected: false, price: 5, durata: 4},
        {id: 21, name:'Anti caduta - da 5,00€', selected: false, price: 5, durata: 4},
    ];
    let barberia = [
        {id: 0, name:'Taglio tradizionale - 15,00€', selected: false, price: 15, durata: 2},
        {id: 1, name:'Razor Fade - 20,00€', selected: false, price: 20, durata: 2},
        {id: 2, name:'Low Fade - 20,00€', selected: false, price: 20, durata: 2},
        {id: 3, name:'Mid Fade - 20,00€', selected: false, price: 20, durata: 2},
        {id: 4, name:'High Fade - 20,00€', selected: false, price: 20, durata: 2},
        {id: 5, name:'Buzz Cut - 20,00€', selected: false, price: 20, durata: 2},
        {id: 6, name:'Spike Low Fade - 20,00€', selected: false, price: 20, durata: 2},
        {id: 7, name:'French Crop - 20,00€', selected: false, price: 20, durata: 2},
        {id: 8, name:'Taper Fade - 20,00€', selected: false, price: 20, durata: 2},
        {id: 9, name:'Barba tradizionale - 5,00€', selected: false, price: 5, durata: 1},
        {id: 10, name:'Sagomatura Barba - 7,00€', selected: false, price: 7, durata: 1},
        {id: 11, name:'Barba Luxury - 10,00€', selected: false, price: 10, durata: 1},
        {id: 12, name:'Hair Tatoo - 10,00€', selected: false, price: 10, durata: 1},
        {id: 13, name:'Sopracciglia - 3,00€', selected: false, price: 3, durata: 1}
    ];
    let estetica = [
        {id: 0, name:'Pedicure Estetica - 15,00€', selected: false, price: 15, durata: 2},
        {id: 1, name:'Semi permanente classico - 10,00€', selected: false, price: 10, durata: 2},
        {id: 2, name:'Semi permanente rinforzato - 15,00€', selected: false, price: 15, durata: 2},
        {id: 3, name:'Ricostruzione - 30,00€', selected: false, price: 30, durata: 2},
        {id: 4, name:'Disegni extra - da 5,00€', selected: false, price: 5, durata: 2}
    ];
    const [esteticaservice, setEstetica] = useState(
        estetica
    );
    const [tecniciservice, setTecnici] = useState(
        tecnici
    );
    const [trattamentiservice, setTrattamenti] = useState(
        trattamenti
    );
    const [barberiaservice, setBarberia] = useState(
        barberia
    )
    const [prezzofinale, setPrezzofinale] = useState(0);
    const [duratafinale, setDuratafinale] = useState(0);
    const [stringafinale, setStringafinale] = useState("");
    const [showntoast, setShown] = useState(false);
    const renderToast = (newArr, codice) => {
        let val = [];
        switch(codice) {
            case 'estetica':
            case 'barberia': {
                newArr.map((item, i) => {
                    if(item.selected === true) {
                        return val.push(item.name);
                    } else
                        return item;
                })
                break;
            }
            case 'tecnici': {
                newArr.map((item, i) => {
                    if(item.selected === true) {
                        return val.push(item.name);
                    } else
                        return item;
                })
                trattamentiservice.map((item, i) => {
                    if(item.selected === true) {
                        return val.push(item.name);
                    } else
                        return item;
                })               
                break;
            }
            case 'trattamenti': {
                tecniciservice.map((item, i) => {
                    if(item.selected === true) {
                        return val.push(item.name);
                    } else
                        return item;
                })
                newArr.map((item, i) => {
                    if(item.selected === true) {
                        return val.push(item.name);
                    } else
                        return item;
                })               
                break;
            }
            default: return('Vuoto');
        }
        if(val.length)
            setShown(true);
    }
    const creaStringafinale = (newArr, codice) => {
        let val = "";
        switch(codice) {
            case 'barberia': 
            case 'estetica': {
                newArr.map((item, i) => {
                    if(item.selected === true) {
                        const diviso = item.name.split('-');
                        return val = val + diviso[0] + ' - ';
                    } else
                        return item;
                })
                break;
            }
            case 'tecnici': {
                newArr.map((item, i) => {
                    if(item.selected === true) {
                        const diviso = item.name.split('-');
                        return val = val + diviso[0] + ' - ';
                    } else
                        return item;
                })
                trattamentiservice.map((item, i) => {
                    if(item.selected === true) {
                        const diviso = item.name.split('-');
                        return val = val + diviso[0] + ' - ';
                    } else
                        return item;
                })               
                break;
            }
            case 'trattamenti': {
                tecniciservice.map((item, i) => {
                    if(item.selected === true) {
                        const diviso = item.name.split('-');
                        return val = val + diviso[0] + ' - ';
                    } else
                        return item;
                })
                newArr.map((item, i) => {
                    if(item.selected === true) {
                        const diviso = item.name.split('-');
                        return val = val + diviso[0] + ' - ';
                    } else
                        return item;
                })               
                break;
            }
            default: return('');
        }
        setStringafinale(val);
    }
    const updateDurata = (newArr, codice) => {
        let val = 0;
        switch(codice) {
            case 'estetica': 
            case 'barberia': {
                newArr.map((item, i) => {
                    if(item.selected === true) {
                        return val+=item.durata;
                    } else
                        return item;
                })
                break;         
            }
            case 'tecnici': {   
                newArr.map((item, i) => {
                    if(item.selected === true) {
                        return val+=item.durata;
                    } else
                        return item;
                })
                trattamentiservice.map((item, i) => {
                    if(item.selected === true) {
                        return val+=item.durata;
                    } else
                        return item;
                })      
                break;
            }
            case 'trattamenti': {     
                tecniciservice.map((item, i) => {
                    if(item.selected === true) {
                        return val+=item.durata;
                    } else
                        return item;
                })      
                newArr.map((item, i) => {
                    if(item.selected === true) {
                        return val+=item.durata;
                    } else
                        return item;
                })            
                break;
            }
            default: return(0);
        }
        setDuratafinale(val);
    }
    const updatePrice = (newArr, codice) => {
        let val = 0;
        switch(codice) {
            case 'estetica':
            case 'barberia': {
                newArr.map((item, i) => {
                    if(item.selected === true) {
                        return val+=item.price;
                    } else
                        return item;
                })
                break;            
            }
            case 'tecnici': {  
                newArr.map((item, i) => {
                    if(item.selected === true) {
                        return val+=item.price;
                    } else
                        return item;
                })
                trattamentiservice.map((item, i) => {
                    if(item.selected === true) {
                        return val+=item.price;
                    } else
                        return item;
                })      
                break;
            }
            case 'trattamenti': {
                tecniciservice.map((item, i) => {
                    if(item.selected === true) {
                        return val+=item.price;
                    } else
                        return item;
                })      
                newArr.map((item, i) => {
                    if(item.selected === true) {
                        return val+=item.price;
                    } else
                        return item;
                })            
                break;
            }
            default: return(0);
        }
        setPrezzofinale(val);
        creaStringafinale(newArr, codice);
        renderToast(newArr, codice);
    }
    const handleClick = () => {
        let val = false;
        esteticaservice.map((item) => {
            if(item.selected)
                val = true;
            return item;
        })
        barberiaservice.map((item) => {
            if(item.selected)
                val = true;
            return item;
        })
        tecniciservice.map((item) => {
            if(item.selected)
                val = true;
            return item;
        });
        trattamentiservice.map((item) => {
            if(item.selected)
                val = true;
            return item;
        });
        if(val === false) {
            setError({ id: 5 });
            showError(!error);
            setTimeout(() => {
                setError({ id: null});
                showError(false);
            }, 2000);
        } else {
            renderState();
        }
    }
    const handleBarberia = (index) => e => {
        const newArr = barberiaservice.map((item) => {
            if (index === item.id) {
                return { ...item, selected: e.target.checked };
            }
            return item;
        });
        setBarberia(newArr);
        updatePrice(newArr, 'barberia');
        updateDurata(newArr, 'barberia');
    }
    const handleEstetica = (index) => e => {
        const newArr = esteticaservice.map((item) => {
            if (index === item.id) {
                return { ...item, selected: e.target.checked };
            }
            return item;
        });
        setEstetica(newArr);
        updatePrice(newArr, 'estetica');
        updateDurata(newArr, 'estetica');
    }
    const handleTrattamenti = (index) => e => {
        const newArr = trattamentiservice.map((item) => {
            if (index === item.id) {
                return { ...item, selected: e.target.checked };
            }
            return item;
        });
        setTrattamenti(newArr);
        updatePrice(newArr, 'trattamenti');
        updateDurata(newArr, 'trattamenti');
    }
    const handleTecnici = (index) => e => {
        const newArr = tecniciservice.map((item) => {
            if (index === item.id) {
                return { ...item, selected: e.target.checked };
            }
            return item;
        });
        setTecnici(newArr);
        updatePrice(newArr, 'tecnici');
        updateDurata(newArr, 'tecnici');
    }
    return (
        <>
        <div className="position-sticky sticky-top">
            <ToastContainer position="top-center">
                <Toast show={showntoast} onClose={() => setShown(false)} delay={3000} autohide>
                    <Toast.Header>
                        <CardChecklist/>
                        <strong style={{marginLeft: '1%'}} className="me-auto">Lista Servizi</strong>
                        <small>pochi secondi fa...</small>
                    </Toast.Header>
                    <Toast.Body>
                        <ul>
                        <>
                        {(() => {
                                const arr = []
                                esteticaservice.map((item, i) => {
                                    if(item.selected == true) {
                                        return arr.push(<li>{item.name}</li>)
                                    }
                                    return item;
                                });
                                barberiaservice.map((item, i) => {
                                    if(item.selected == true) {
                                        return arr.push(<li>{item.name}</li>)
                                    }
                                    return item;
                                });
                                tecniciservice.map((item, i) => {
                                    if(item.selected == true) {
                                        return arr.push(<li>{item.name}</li>)
                                    }
                                    return item;
                                });
                                trattamentiservice.map((item, i) => {
                                    if(item.selected == true) {
                                        return arr.push(<li>{item.name}</li>)
                                    }
                                    return item;
                                });
                                return arr;
                            })()}
                            </>
                            </ul>
                   </Toast.Body>
                </Toast>
            </ToastContainer>
        </div>
        {operatortype == 0 ? <>
        <Col xs="12">
            <p>Non effettuiamo acconciature.</p>
        </Col>
        <Col className="boxshadow" lg = {{span: 5, offset: 0}}>
            <h1>Lavori Tecnici</h1>
            {
                tecniciservice.map((tecnicis) => (
                    <Form.Check
                        key={tecnicis.id}
                        label={tecnicis.name}
                        id={tecnicis.id}
                        onChange={handleTecnici(tecnicis.id)}
                    />
                ))
            }
        </Col>
        <Col className="boxshadow" lg = {{span: 5, offset: 2}}>
            <h1>Trattamenti</h1>
            {
                trattamentiservice.map((trattamentis) => (
                    <Form.Check
                        key={trattamentis.id}
                        label={trattamentis.name}
                        id={trattamentis.id}
                        onChange={handleTrattamenti(trattamentis.id)}
                    />
                ))
            }
        </Col>
        </> : operatortype == 1 ?
        <>
        <Col className="boxshadow" xs={12}>
            <h1>Barberia</h1>
            {
                barberiaservice.map((barberias) => (
                    <Form.Check
                        key={barberias.id}
                        label={barberias.name}
                        id={barberias.id}
                        onChange={handleBarberia(barberias.id)}
                    />
                ))
            }
        </Col>
        </>
        :
        <>
        <Col className="boxshadow" xs={12}>
            <h1>Estetica</h1>
            {
                esteticaservice.map((esteticas) => (
                    <Form.Check
                        key={esteticas.id}
                        label={esteticas.name}
                        id={esteticas.id}
                        onChange={handleEstetica(esteticas.id)}
                    />
                ))
            }
        </Col>    
        </>
        }
        <Col className="sticky-bottom position-sticky" xs={12} style={{textAlign: 'right'}}>
            <Button className={(prezzofinale > 0 ? "" : "d-none")} style = {{marginTop: '1em', marginBottom: '1em'}} onClick = {handleClick}  disabled={error} variant="light">Vai al riepilogo <ArrowRight/></Button>
        </Col> 
        <Col className="sticky-bottom position-sticky" style={{textAlign: 'left'}}>
            { error ? <Errore error={typerror}/> : null}
        </Col>
        </>
    );
}

export default Servizi;