import React, { useEffect } from 'react';
import MenuNav from './MenuNav';
import Footer from './Footer';
import { Animator, ScrollContainer, ScrollPage, batch, Fade, FadeIn, FadeOut, Move, MoveIn, MoveOut, Sticky, StickyIn, StickyOut, Zoom, ZoomIn, ZoomOut } from "react-scroll-motion";
import {Helmet} from "react-helmet";
import Image from 'react-bootstrap/esm/Image'
import { GoogleMap, useJsApiLoader, MarkerF } from '@react-google-maps/api';
import {ArrowRight} from 'react-bootstrap-icons'
import Background from './beautyhairfavic.jpg';
import toast, {Toaster} from 'react-hot-toast';
function Home({socket}) {
    
    const containerStyle = {
        width: '100%',
        height: '400px'
    };
    const zoom = 15;
    const center = {
        lat: 38.136517,
        lng: 13.345178
    };
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyAlwoitsGXRtdbV6yzSLiWeLitCkT-qoZg"
    })
    const ZoomInScrollOut = batch(StickyIn(), FadeIn(), ZoomIn(), Fade());
    const FadeUp = batch(Fade(), Move(), Sticky());
    const [map, setMap] = React.useState(null)

    const onLoad = React.useCallback(function callback(map) {
      // This is just an example of getting and using the map instance!!! don't just blindly copy!
      map.setZoom(zoom)
      setMap(map)
    }, [])
  
    const onUnmount = React.useCallback(function callback(map) {
      setMap(null)
    }, [])
    useEffect(() => {
        toast("Da Lunedì 03/06/2024 saremo aperti Dal Lunedì al Venerdì con i nostri orari estivi, dalle ore 08:00 alle 18:00",
            {
                duration: 4000,
                position: 'bottom-center',
              
                // Styling
                style: {},
                className: '',
              
                // Custom Icon
                icon: '🏖️',
            }
        );
    },[])
    return (
        <>
        <Helmet>
            <title>Salone di Parrucchieria - Beauty Hair Palermo</title>
            <meta name="description" content="Beauty Hair, salone di Parrucchieria a Palermo in via Giovanni Prati 32/34. Prenota ora il tuo servizio, aperti dal martedì al sabato. Per info: 3477209011"/>
            <meta property="og:title" content="Salone di Parrucchieria - Beauty Hair Palermo" />
            <meta property="og:type" content="website" />
            <meta property="og:url" content="https://beautyhairpalermo.it/" />
            <meta property="og:image" content="https://beautyhairpalermo.it/beautyhairfavic.jpg" />    
        </Helmet>
        <MenuNav active={{path: '/'}}/>
        <div className="Principale">
            <Toaster/>
            <ScrollContainer>
                <ScrollPage style={{textAlign: 'center', background: '#000000'}}>
                    <Animator animation={batch(Fade(), Sticky(), MoveOut(0, -200))}>
                        <h1 style={{fontSize: '45px'}}>Beauty Hair di Giada Messina</h1>
                    </Animator>
                </ScrollPage>
                <ScrollPage style={{textAlign: 'center', background: '#000000'}}>

                    <Animator animation={FadeUp}>
                        <div style={{backgroundImage: `url(${Background})`, backgroundSize: 'cover'}}>
                        </div>
                    </Animator>
                </ScrollPage>
                <ScrollPage style={{textAlign: 'center', background: '#000000'}}>
                </ScrollPage>
                <ScrollPage style={{textAlign: 'center'}}>
                    <Animator animation={FadeUp}>
                        <h2>Vieni a visitare il nostro salone...</h2>
                    </Animator>
                </ScrollPage>
                <ScrollPage style={{textAlign: 'center'}}>
                    <Animator animation={FadeUp}>               
                        <h2>Ti troverai benissimo!</h2>   
                    </Animator>     
                </ScrollPage>
                <ScrollPage style={{textAlign: 'center'}}>
                    <Animator animation={FadeUp}><h2>Ci troviamo in via Giovanni Prati 32/34, a Palermo</h2></Animator>
                </ScrollPage>
                <ScrollPage style={{textAlign: 'center'}}>
                    <Animator animation={FadeUp}><h2>Proprio accanto a Piazza Unità D'Italia!</h2></Animator>
                </ScrollPage>
                <ScrollPage style={{textAlign: 'center', background: '#000'}}>
                    <br/>
                    <br/>
                    <Animator animation={batch(MoveIn(0,500), Fade())}>
                    {isLoaded ? 
                    <GoogleMap
                        mapContainerStyle={containerStyle}
                        center={center}
                        zoom={zoom}
                        onLoad={onLoad}
                        onUnmount={onUnmount}
                    >
                        {
                            <MarkerF
                                label={{text: 'Beauty Hair di Giada Messina', className:'Marker'}}
                                position={center}
                            />
                        }
                        <></>
                    </GoogleMap>
                    : null }
                    </Animator>
                </ScrollPage>
                <ScrollPage style={{textAlign: 'center', background: '#000'}}>
                </ScrollPage>
                <ScrollPage style={{textAlign: 'center', background: '#000'}}>
                    <Animator animation={FadeUp}>
                        <h2 style={{color: 'white'}}>Abbiamo a disposizione anche...</h2>
                    </Animator>
                </ScrollPage>
                <ScrollPage style={{textAlign: 'center', background: '#000'}}>
                    <Animator animation={FadeUp}>
                        <h2 style={{color: 'white'}}>Un Parcheggio Convenzionato!</h2>
                    </Animator>
                </ScrollPage>
                <ScrollPage style={{textAlign: 'center', background: '#000'}}>
                    <Animator animation={FadeUp}>
                        <h2 style={{color: 'white'}}>A meno di 20m dal nostro salone...</h2>
                    </Animator>
                </ScrollPage>
                <ScrollPage style={{textAlign: 'center', background: '#000'}}>
                    <Animator animation={FadeUp}>
                        <h2 style={{color: 'white'}}>Si trova "Sperlinga Parking" in via Cesareo 16</h2>
                    </Animator>
                </ScrollPage>
                <ScrollPage style={{background: '#fff'}}>
                </ScrollPage>
                <ScrollPage style={{background: '#fff', textAlign: 'center'}}>
                    <h2>Ecco alcuni dei nostri lavori:</h2>
                </ScrollPage>  
                <ScrollPage>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }} >
                        <Animator amimation={MoveIn(-1000, 0)}>
                            <Image
                                src="./Beautyhairgallery.JPG"
                                fluid
                                alt="Beauty Hair di Giada Messina a Palermo, i lavori del nostro salone di parrucchieria"
                            />
                        </Animator>
                        <Animator animation={MoveIn(0, 1000)}>
                            <Image
                                src="./Beautyhairgallery3.JPG"
                                fluid
                                alt="Beauty Hair di Giada Messina a Palermo, i lavori del nostro salone di parrucchieria"
                            />
                        </Animator>
                        <Animator animation={MoveIn(-1000, 0)}>
                            <Image
                                src="./Beautyhairgallery4.JPG"
                                fluid
                                alt="Beauty Hair di Giada Messina a Palermo, i lavori del nostro salone di parrucchieria"
                            />
                        </Animator>
                    </div>
                </ScrollPage>
                <ScrollPage style={{background: '#000', textAlign: 'center'}}>
                    <Animator animation={FadeUp}>

                    </Animator>
                </ScrollPage>  
                <ScrollPage style={{background: '#000', textAlign: 'center'}}>
                    <Animator animation={FadeUp}>
                        <h2 style={{color: 'white'}}>Che aspetti?</h2>              
                    </Animator>
                </ScrollPage>  
                <ScrollPage style={{background: '#000', textAlign: 'center'}}>
                    <Animator animation={FadeUp}>
                        <a style={{textDecorationColor: 'white'}} href="/prenota"><h2 style={{color: 'white'}}>Visualizza il calendario <ArrowRight/></h2></a>
                    </Animator>
                </ScrollPage>
                <ScrollPage style={{background: '#000', textAlign: 'center'}}>
                    <Animator animation={FadeUp}>
                   </Animator>
                </ScrollPage>
            </ScrollContainer>
            <Footer/>
        </div>
        </>
    );
}

export default Home