import {React, useState, useEffect, useRef } from 'react';
import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import { ArrowRight, ArrowLeft, Calendar2Event } from 'react-bootstrap-icons';
import axios from 'axios';
import LoadSpinner from './LoadSpinner';
function Calendario({updateTableid, updateYearid, updateActiveitem, updateActiveoperator, updateActiveorario, updateState, updateActiveoperatortype}) {
    const myRef = useRef(null)
    const executeScroll = () => myRef.current.scrollIntoView()    
    const renderState = () => {
        updateState();
    };
    const [aperture, setAperture] = useState([]);
    const [orestandard, setOreStandard] = useState([]);
    const [allore, setAllOre] = useState([])
    const [alloper, setAllOper] = useState([])
    const [allpren, setAllPren] = useState([]);
    const [operatori, setOperatori] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const renderActiveorario = (val) => {
        updateActiveorario(val);
    }
    const renderActiveoperator = (val) => {
        updateActiveoperator(val);
    }
    const renderActiveoperatortype = (val) => {
        updateActiveoperatortype(val);
    }
    const [activeorario, setActiveorario] = useState(null);
    const [activeoperator, setActiveoperator] = useState(null);
    const [activeoperatortype, setActiveOperType] = useState(null);
    const listaorari = [
        {id: 0, hour: '08:30', disabled: false, operatore: 0},
        {id: 1, hour: '08:45', disabled: false, operatore: 0},
        {id: 2, hour: '09:00', disabled: false, operatore: 0},
        {id: 3, hour: '09:15', disabled: false, operatore: 0},
        {id: 4, hour: '09:30', disabled: false, operatore: 0},
        {id: 5, hour: '09:45', disabled: false, operatore: 0},
        {id: 6, hour: '10:00', disabled: false, operatore: 0},
        {id: 7, hour: '10:15', disabled: false, operatore: 0},
        {id: 8, hour: '10:30', disabled: false, operatore: 0},
        {id: 9, hour: '10:45', disabled: false, operatore: 0},
        {id: 10, hour: '11:00', disabled: false, operatore: 0},
        {id: 11, hour: '11:15', disabled: false, operatore: 0},
        {id: 12, hour: '11:30', disabled: false, operatore: 0},
        {id: 13, hour: '11:45', disabled: false, operatore: 0},
        {id: 14, hour: '12:00', disabled: false, operatore: 0},
        {id: 15, hour: '12:15', disabled: false, operatore: 0},
        {id: 16, hour: '12:30', disabled: false, operatore: 0},
        {id: 17, hour: '12:45', disabled: false, operatore: 0},
        {id: 18, hour: '13:00', disabled: false, operatore: 0},
        {id: 19, hour: '13:15', disabled: false, operatore: 0},
        {id: 20, hour: '13:30', disabled: false, operatore: 0},
        {id: 21, hour: '13:45', disabled: false, operatore: 0},
        {id: 22, hour: '14:00', disabled: false, operatore: 0},
        {id: 23, hour: '14:15', disabled: false, operatore: 0},
        {id: 24, hour: '14:30', disabled: false, operatore: 0},
        {id: 25, hour: '14:45', disabled: false, operatore: 0},
        {id: 26, hour: '15:00', disabled: false, operatore: 0},
        {id: 27, hour: '15:15', disabled: false, operatore: 0},
        {id: 28, hour: '15:30', disabled: false, operatore: 0},
        {id: 29, hour: '15:45', disabled: false, operatore: 0},
        {id: 30, hour: '16:00', disabled: false, operatore: 0},
        {id: 31, hour: '16:15', disabled: false, operatore: 0},
        {id: 32, hour: '16:30', disabled: false, operatore: 0},
        {id: 33, hour: '16:45', disabled: false, operatore: 0},
        {id: 34, hour: '17:00', disabled: false, operatore: 0},
        {id: 35, hour: '17:15', disabled: false, operatore: 0},
        {id: 36, hour: '17:30', disabled: false, operatore: 0},
        {id: 37, hour: '17:45', disabled: false, operatore: 0},
        {id: 38, hour: '18:00', disabled: false, operatore: 0},
        {id: 39, hour: '18:15', disabled: false, operatore: 0},

    ];
    const [orari, setOrari] = useState(
        listaorari
    );
    const handleOrarioClick = event => {
        const hourval = event.currentTarget.getAttribute("data-hour");
        const operatorval = event.currentTarget.getAttribute("data-operator");
        const operatortype = event.currentTarget.getAttribute("data-opertype");
        setActiveoperator(operatorval);
        setActiveorario(hourval);
        setActiveOperType(operatortype);
        //renderActiveoperator(operatorval);
        //renderActiveorario(hourval);
        setTimeout(()=> {
            setDisplaynone(true);
        },1500);
        setTimeout(()=> {
            renderState();
            renderActiveitem(activeitem);
            renderActiveorario(hourval);
            renderActiveoperator(operatorval);
            renderActiveoperatortype(operatortype);
            renderTableid(tableid);
            renderYearid(yearid);
        }, 1500);

    }
    const handleOrari = async(idval) => {
        const newArr = resetHour();
        let numOper = await getNumOperatori(idval);
        const finalArr = [];
        let id = 0;

        for(let i = 1; i < operatori.length+1; i++) {
            for(let b = 0; b < numOper.length; b++)
                if(numOper[b].RifOperatore === i)
                    i++;
            if(i < operatori.length+1) {
                newArr.map((item) => {
                    id++;
                    return finalArr.push({...item, id: id, operatore: i});
                });
            }
        }
        setOrari(finalArr);
        return finalArr;
    }
    const resetHour = () => {
        setOrari(listaorari);
        return listaorari;
    }
    const getHour = async(idval, orarival) => {
        const hourOccupied = await getPren(idval);
        let hourVal = orarival;
        const operOccupied = await getOper(idval);
        const todayDate = new Date();
        const idvaldate = new Date(getNewData(idval));
        const time = todayDate.getHours() + ':' + todayDate.getMinutes();
        const timeseparated = time.split(':');
        //console.log(timeseparated)
        //console.log(todayDate, idvaldate, time);
        let value = false;
        hourVal = hourVal.map((item) => {
            for(let c = 0; c < orestandard.length; c++) {
                if(orestandard[c].Operatore === item.operatore) {
                    if(item.hour === orestandard[c].RifOra) {
                        if(orestandard[c].Attivo) {
                            value = false;
                        } else {
                            value = true;
                        }
                        return {...item, disabled: value}
                    }
                }
            }
            return item;
        });
        setOrari(hourVal);
        if(typeof(operOccupied) !== 'undefined') {
            hourVal = hourVal.map((item) => {
                for(let c = 0; c < operatori.length; c++) {
                    for(let i = 0; i < operOccupied.length; i++) {
                        if(item.operatore === operatori[c].ID) {
                            if(item.operatore === operOccupied[i].RifOperatore) {
                                if(item.hour === operOccupied[i].Ora) {
                                    if(operOccupied[i].Attivo)
                                        return {...item, disabled: false}
                                    else
                                        return {...item, disabled: true}
                                }
                            }
                        }
                    }
                }
                return item;
            });
            setOrari(hourVal);
        }
        if(todayDate.setHours(0,0,0,0) === idvaldate.setHours(0,0,0,0)) {
            hourVal = hourVal.map((item) => {
                todayDate.setHours(timeseparated[0], timeseparated[1], 0, 0);
                const hoursep = item.hour.split(':');
                idvaldate.setHours(hoursep[0], hoursep[1], 0, 0);
                if(todayDate > idvaldate) {
                    return {...item, disabled: true}
                }
                return item;
            });
            setOrari(hourVal);
        }
        hourVal = hourVal.map((item) => {
            for(let c = 0; c < operatori.length; c++) {
                for(let i = 0; i < hourOccupied.length; i++) {
                    if(hourOccupied[i].Operatore === operatori[c].ID) {
                        if(item.operatore === hourOccupied[i].Operatore) {
                            if(item.hour === hourOccupied[i].Ora) {
                                return {...item, disabled: true}
                            }
                        }
                    }
                }
            }
            return item;
        });
        setOrari(hourVal);

        return hourVal;
    }
    useEffect( () => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        const getAperture = async() => {
            //axios.get('http://localhost:3001/getapertura')
            axios.get('https://beautyhairpalermo.it:3001/getapertura')
            .then(res=>setAperture(res.data))
            .catch(error => console.log(error))
        }
        const getOperatori = async() => {
            //axios.get('http://localhost:3001/getnomeoperatori')
            axios.get('https://beautyhairpalermo.it:3001/getnomeoperatori')
            .then(res=>setOperatori(res.data))
            .catch(error => console.log(error))
        }
        const getOre = async() => {
            //axios.get('http://localhost:3001/getorestandard')
            axios.get('https://beautyhairpalermo.it:3001/getorestandard')
            .then(res=>setOreStandard(res.data))
            .catch(error => console.log(error))
        }
        const getAllPren = async() => {
           // axios.get('http://localhost:3001/getallpren')
            axios.get('https://beautyhairpalermo.it:3001/getallpren')
            .then(res=>setAllPren(res.data))
            .catch(error => console.log(error))
        }
        const getAllOre = async() => {
            //axios.get('http://localhost:3001/getallore')
            axios.get('https://beautyhairpalermo.it:3001/getallore')
            .then(res=>setAllOre(res.data))
            .catch(error => console.log(error))          
        }
        const getAllOper = async() => {
            //axios.get('http://localhost:3001/getalloper')
            axios.get('https://beautyhairpalermo.it:3001/getalloper')
            .then(res=>setAllOper(res.data))
            .catch(error => console.log(error))          
        }
        getOre();
        getAperture();
        getOperatori();
        getAllPren();
        getAllOre();
        getAllOper();
    }, []);
    const handleDate = () => {
        let find = false;
        const datatoday = returnDataOggi();
        const finalArr = [{Data: datatoday}];
        for(let i = 0; i < allore.length; i++) {
            find = false;
            finalArr.some(element =>  {
                if(element.Data === allore[i].Data)
                    find = true;
            });
            if(!find)
                finalArr.push({Data: allore[i].Data});
        }
        for(let i = 0; i < allpren.length; i++) {
            find = false;
            finalArr.some(element =>  {
                if(element.Data === allpren[i].Data)
                    find = true;
            });
            if(!find)
                finalArr.push({Data: allpren[i].Data});
        }
        for(let i = 0; i < alloper.length; i++) {
            find = false;
            finalArr.some(element =>  {
                if(element.Data !== alloper[i].Data)
                    find = true;
            });
            if(!find)
                finalArr.push({Data: alloper[i].Data});
        }
        return finalArr;
    }
    const createOrari = () => {
        const arrDate = handleDate();
        const finalArr = [];
        const listaore = listaorari;
        for(let b = 0; b < arrDate.length; b++) {
            let id = 0;
            for(let i = 1; i < operatori.length+1; i++) {
                listaore.map((item) => {
                    id++;
                    return finalArr.push({...item, id: id, data: arrDate[b].Data, operatore: i});
                });
            }
        }
        return finalArr;
    }
    const handlePren = () => {
        let newArr = createOrari();
        const todayDate = new Date();
        const time = todayDate.getHours() + ':' + todayDate.getMinutes();
        const timeseparated = time.split(':');
        for(let i = 0; i < orestandard.length; i++) {
            newArr = newArr.map((item) => {
                if(orestandard[i].RifOra === item.hour) {
                    if(orestandard[i].Operatore === item.operatore) {
                        if(orestandard[i].Attivo === 0) {
                            return {...item, disabled: true}
                        }
                    }
                }
                return item;
            });
        }
        for(let i = 0; i < alloper.length; i++) {
            newArr = newArr.map((item) => {
                if(alloper[i].Data === item.data) 
                    if(alloper[i].RifOperatore === item.operatore)
                        if(alloper[i].Attivo === 0)
                            return {...item, disabled: true}
                return item;
            });
        }
        for(let i = 0; i < allore.length; i++) {
            newArr = newArr.map((item) => {
                if(allore[i].RifOperatore === item.operatore)
                    if(allore[i].Ora === item.ora)
                        if(allore[i].Attivo === 0)
                            return {...item, disabled: true}
                        else
                            return {...item, disabled: false}
                return item;
            });
        }
        for(let i = 0; i < allpren.length; i++) {
            newArr = newArr.map((item) => {
                if(allpren[i].Data === item.data)
                    if(allpren[i].Ora === item.hour)
                        if(allpren[i].Operatore === item.operatore)
                            return {...item, disabled: true}
                return item;
            });
        }
        newArr = newArr.map((item) => {
            const idvaldate = new Date(item.data);
            if(todayDate.setHours(0,0,0,0) === idvaldate.setHours(0,0,0,0)) {
                todayDate.setHours(timeseparated[0], timeseparated[1], 0, 0);
                const hoursep = item.hour.split(':');
                idvaldate.setHours(hoursep[0], hoursep[1], 0, 0);
                if(todayDate > idvaldate) {
                    return {...item, disabled: true}
                }
            }
            return item;
        });
        return newArr;
    }
    const createDatePiene = () => {
        let arrDate = handleDate();
        let newArr = handlePren();
        let find = false;
        let find2 = false;
        const finalArr = ([]);
        if(typeof(newArr !== "undefined")) {
            if(typeof(arrDate !== "undefined")) {
                for(let i = 0; i < arrDate.length; i++) {
                    find2 = false;
                    newArr.map((item) => {
                        if(item.data === arrDate[i].Data) {
                            if(item.disabled === false) {
                                find2 = true;
                                find = true;
                            }
                            if(find2 === false) {
                                find = false;
                                finalArr.some(element =>  {
                                    if(element.Data === arrDate[i].Data)
                                        find = true;
                                });
                            }
                        }
                        return item;
                    })
                    if(!find && !find2)
                        finalArr.push({Data: arrDate[i].Data});
                }
            }
        }
        return finalArr;
    }
    const getNumOperatori = async(idval) => {
        let val = undefined;
        const params = {
            data: getNewData(idval)
        }
        //await axios.get('http://localhost:3001/getnumoperatori', {
        await axios.get('https://beautyhairpalermo.it:3001/getnumoperatori', {
            params
        })
        .then(res=>{ val = res.data})
        .catch(error => console.log(error))         
        return val;
    }
    const getOper = async (idval) => {
        let val = undefined;
        const params = {
            data: getNewData(idval)
        };
       // await axios.get('http://localhost:3001/getapertura', {
        await axios.get('https://beautyhairpalermo.it:3001/getoper', {
            params
        })
        .then(res=>{ val = res.data})
        .catch(error => console.log(error))
        return val;
    }
    const getPren = async(idval) => {
        let val = undefined;
        const params = {
            data: getNewData(idval)
        };
       //await axios.get('http://localhost:3001/getpren', {

        await axios.get('https://beautyhairpalermo.it:3001/getpren', {
            params
        })
        .then(res=>{ val = res.data})
        .catch(error => console.log(error))  
        return val;     
    }
    const getNewData = (contatore) => {
        return yearid+'-'+(tableid < 10 ? '0'+(tableid+1) : (tableid+1))+'-'+(contatore < 10 ? '0'+contatore : contatore)
    }
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();
    const [tableid, setTableID] = useState(currentMonth);
    const [yearid, setYearID] = useState(currentYear);
    const [back, setBack] = useState(true);
    const [activeitem, setActiveitem] = useState(null);
    const renderTableid = (val) => {
        updateTableid(val);
    }
    const renderYearid = (val) => {
        updateYearid(val);
    }
    const renderActiveitem = (val) => {
        updateActiveitem(val);
    }
    const dataoggi = new Date();
    const dayoggi = dataoggi.getDate();
    const returnDataOggi = () => {
        const year = dataoggi.getFullYear();
        const month = dataoggi.getMonth()+1;
        const day = dataoggi.getDate();
        return year+'-'+(month < 10 ? '0'+month : month)+'-'+(day< 10 ? '0'+day : day);
    }
    const [displaynone, setDisplaynone] = useState(false);
    const handleClick = async event => {
        let idval = event.currentTarget.id;
        setIsLoaded(false);
        setActiveitem(idval);
        setActiveoperator(null);
        setActiveorario(null);
        //renderActiveitem(idval);
        //renderActiveoperator(null);
        //renderActiveorario(null);
        const nuovoval = await handleOrari(idval);
        const val = await getHour(idval, nuovoval);
        setIsLoaded(true);
        setDisplaynone(false);
       // setActiveoperator(null);
       // setActiveorario(null);
        //renderActiveoperator(null);
       // renderActiveorario(null);
       executeScroll();
    }
    const handleBack = () => {
        let backval = tableid-1;
        let yearval = yearid;
        if(backval < 0) {
            backval = 11;
            yearval = yearid-1;
            setYearID(yearval);
            //renderYearid(yearval);
        }
        setTableID(backval);
        //renderTableid(backval);
        if((backval) === currentMonth && (yearval) === currentYear) {
            setBack(true);
        }
        const arrayval = translateInDays(getDaysInMonth(backval, yearval));
        const arraylength = arrayval.length;
        setArrayday(arrayval);
        setLenghtday(arraylength);
        const activeval = null;
        setActiveitem(activeval);
    }
    const handleForward = () => {
        let forwardval = tableid+1;
        let yearval = yearid;
        if(forwardval > 11) {
            forwardval = 0;
            yearval = yearid+1;
            setYearID(yearval);
        }
        setTableID(forwardval);
        //renderTableid(forwardval);
        setBack(false);
        const arrayval = translateInDays(getDaysInMonth(forwardval, yearval));
        const arraylength = arrayval.length;
        setArrayday(arrayval);
        setLenghtday(arraylength);
        const activeval = null;
        setActiveitem(activeval);
        //renderActiveitem(activeval);
    }
    function getMonthName(month) {
        switch(month) {
            default: return("Errore");
            case 0: return("Gennaio");
            case 1: return("Febbraio")
            case 2: return("Marzo");
            case 3: return("Aprile");
            case 4: return("Maggio")
            case 5: return("Giugno");
            case 6: return("Luglio");
            case 7: return("Agosto")
            case 8: return("Settembre");
            case 9: return("Ottobre");
            case 10: return("Novembre")
            case 11: return("Dicembre");
        }
    }
    function getDaysInMonth(month, year) {
        var date = new Date(year, month, 1);
        var days = [];
        while (date.getMonth() === month) {
            days.push(new Date(date));
            date.setDate(date.getDate() + 1);
        }
        return days;
    }

    function translateInDays(arr) {
        var days = [];
        for(let i = 0; i < arr.length; i++) {
            days.push(arr[i].getDay());
        }
        return days;
    }
    const [arrayday, setArrayday] = useState(translateInDays(getDaysInMonth(tableid, yearid)));
    const [lengthday, setLenghtday] = useState(arrayday.length);

    return (
        <>
        <Col style={{textAlign: 'left'}} xs={6}>
            <Button style={{marginTop: '1rem'}} variant="outline-light" onClick={handleBack} disabled={back}><ArrowLeft/> Indietro</Button>
        </Col>
        <Col style={{textAlign: 'right'}} xs={6}>
            <Button style={{marginTop: '1rem'}} variant="outline-light" onClick={handleForward}>Avanti <ArrowRight/></Button>
        </Col>
        <h1 style={{textAlign: 'center'}}><Calendar2Event/> {getMonthName(tableid) + ' ' + yearid}</h1>
        <Table cellSpacing="0" className="CalendarioStyle" variant="dark" responsive>
            <thead>
                <tr>
                    <th>L</th>
                    <th>M</th>
                    <th>M</th>
                    <th>G</th>
                    <th>V</th>
                    <th>S</th>
                    <th>D</th>
                </tr>
            </thead>
            <tbody>
                <>
                    {(() => {
                        const arr = [];
                        let arr2 = [];
                        const firstval = parseInt(arrayday[0]);
                        let contatore = 1;
                        let newdata = getNewData();
                        let val = false;
                        const arrDate = createDatePiene();
                        while(contatore <= lengthday) {
                            for(let b = 0; b < 7; b++) {
                                /*if(b === 0)
                                    arr.push(<tr></tr>);*/
                                if(contatore === 1 && (b !== firstval && firstval !== 0)) {
                                    console.log('entro qui')
                                    while(b < firstval-1) {
                                        b++;
                                        arr2.push(<td className={`empty ${(activeorario != null && activeoperator != null) ? 'displaynoneorari ' : ''} ${(activeorario != null && activeoperator != null) && displaynone ? 'd-none' : ''}`}></td>);
                                    }
                                } else if(contatore === 1 && firstval === 0) {
                                    console.log('anzi no qui');
                                    b = 6;
                                    while(b > firstval) {
                                        b--;
                                        arr2.push(<td className={`empty ${(activeorario != null && activeoperator != null) ? 'displaynoneorari ' : ''} ${(activeorario != null && activeoperator != null) && displaynone ? 'd-none' : ''}`}></td>);
                                    }        
                                    b = 6;                
                                }
                                if(contatore >= lengthday+1)
                                    arr2.push(<td className={`empty ${(activeorario != null && activeoperator != null) ? 'displaynoneorari ' : ''} ${(activeorario != null && activeoperator != null) && displaynone ? 'd-none' : ''}`}></td>);
                                else {
                                    newdata = getNewData(contatore);
                                    val = false;
                                    if(tableid !== currentMonth || yearid !== currentYear) {
                                        arrDate.some(element => {
                                            if(element.Data === newdata) {
                                                val = true;
                                                return arr2.push(<td className={`full ${(activeorario != null && activeoperator != null) ? 'displaynoneorari ' : ''} ${(activeorario != null && activeoperator != null) && displaynone ? 'd-none' : ''}`}>{contatore}</td>);
                                            }
                                        })
                                        if(val === false) {
                                            if(b === 0 || b === 6) {
                                                aperture.map((value, key) => {
                                                    if(value.Aperta === newdata) {
                                                        val = true;
                                                        return arr2.push(<td id = {contatore} className={`${(activeitem == contatore) ? 'selected ' : ''} ${(activeorario != null && activeoperator != null && activeitem != contatore) ? 'displaynoneorari ' : ''} ${(activeorario != null && activeoperator != null && activeitem != contatore) && displaynone ? 'd-none' : ''}`} onClick={handleClick}>{contatore}</td>);
                                                    }
                                                    return false;
                                                    
                                                })
                                                if(val === false)
                                                    arr2.push(<td className={`empty ${(activeorario != null && activeoperator != null) ? 'displaynoneorari ' : ''} ${(activeorario != null && activeoperator != null) && displaynone ? 'd-none' : ''}`}>{contatore}</td>);
                                            } else {
                                                aperture.map((value, key) => {
                                                    if(value.Festivo === newdata) {
                                                        val = true;
                                                        return arr2.push(<td className={`festivo ${(activeorario != null && activeoperator != null) ? 'displaynoneorari ' : ''} ${(activeorario != null && activeoperator != null) && displaynone ? 'd-none' : ''}`}>{contatore}</td>);
                                                    }
                                                    return false;
                                                })
                                                if(val === false)
                                                    arr2.push(<td id = {contatore} className={`${(activeitem == contatore) ? 'selected ' : ''} ${(activeorario != null && activeoperator != null && activeitem != contatore) ? 'displaynoneorari ' : ''} ${(activeorario != null && activeoperator != null && activeitem != contatore) && displaynone ? 'd-none' : ''}`} onClick={handleClick}>{contatore}</td>);
                                            }
                                        }
                                    } else {
                                        if(contatore < dayoggi)
                                            arr2.push(<td className={`empty ${(activeorario != null && activeoperator != null) ? 'displaynoneorari ' : ''} ${(activeorario != null && activeoperator != null) && displaynone ? 'd-none' : ''}`}>{contatore}</td>);
                                        else {
                                            arrDate.some(element => {
                                                if(element.Data === newdata) {
                                                    val = true;
                                                    return arr2.push(<td className={`full ${(activeorario != null && activeoperator != null) ? 'displaynoneorari ' : ''} ${(activeorario != null && activeoperator != null) && displaynone ? 'd-none' : ''}`}>{contatore}</td>);
                                                }
                                            })
                                            if(val === false) {
                                                if(b === 0 || b === 6) {
                                                    aperture.map((value, key) => {
                                                        if(value.Aperta === newdata) {
                                                            val = true;
                                                            return arr2.push(<td id = {contatore} className={`${(activeitem == contatore) ? 'selected ' : ''} ${(activeorario != null && activeoperator != null && activeitem != contatore) ? 'displaynoneorari ' : ''} ${(activeorario != null && activeoperator != null && activeitem != contatore) && displaynone ? 'd-none' : ''}`} onClick={handleClick}>{contatore}</td>);
                                                        }
                                                        return false;
                                                    })
                                                    if(val === false)
                                                        arr2.push(<td className={`empty ${(activeorario != null && activeoperator != null) ? 'displaynoneorari ' : ''} ${(activeorario != null && activeoperator != null) && displaynone ? 'd-none' : ''}`}>{contatore}</td>);
                                                } else {
                                                    aperture.map((value, key) => {
                                                        if(value.Festivo === newdata) {
                                                            val = true;
                                                            return arr2.push(<td className={`festivo ${(activeorario != null && activeoperator != null) ? 'displaynoneorari ' : ''} ${(activeorario != null && activeoperator != null) && displaynone ? 'd-none' : ''}`}>{contatore}</td>);
                                                        }
                                                        return false;
                                                    })
                                                    if(val === false)
                                                        arr2.push(<td id = {contatore} className={`${(activeitem == contatore) ? 'selected ' : ''} ${(activeorario != null && activeoperator != null && activeitem != contatore) ? 'displaynoneorari ' : ''} ${(activeorario != null && activeoperator != null && activeitem != contatore) && displaynone ? 'd-none' : ''}`} onClick={handleClick}>{contatore}</td>);
                                                }
                                            }
                                        }
                                    }
                                }
                                contatore++;
                                if(b === 6) {
                                    arr.push(<tr>{arr2}</tr>);
                                    arr2 = [];
                                }
                            }
                        }
                        return arr;   
                    })()}
                </>
            </tbody>

        </Table>
        <Col>
        <div ref={myRef} className={`${activeitem ? '' : 'd-none'} orariList`}>
            <hr/>
            {(() => {
                const arr = [];
                if(isLoaded === true) {
                for(let i = 1; i < operatori.length+1; i++) {
                    let nomeSet = false;
                    orari.map((item) => {
                        if(item.operatore === i) {
                            if(nomeSet === false) {
                                arr.push(<Col md={6}></Col>);
                                arr.push(<h1 className={`${(activeorario != null && activeoperator != null) && (activeoperator != item.operatore) ? 'displaynoneorari ' : ' '} ${(activeoperator != item.operatore) && displaynone ? 'd-none' : ''}`}>{operatori[i-1].Nome}</h1>)
                                nomeSet = true;
                            }
                            if(item.disabled === false) {
                                return arr.push(<Button disabled={activeorario ? true : false} variant="" data-operator = {item.operatore} data-opertype = {operatori[i-1].Tipo} data-hour = {item.hour} className={`buttonorario ${activeorario == item.hour && activeoperator == item.operatore ? "selectedorario" : ""} ${(activeorario != null && activeoperator != null) && (activeorario != item.hour || activeoperator != item.operatore) ? 'displaynoneorari ' : ' '} ${(activeorario != item.hour || activeoperator != item.operatore) && displaynone ? 'd-none' : ''}`} onClick={handleOrarioClick} key={i+'abc'+item.hour}>{item.hour}</Button>);     
                            }
                        }
                        return false;
                    });
                }
                } else {
                    arr.push(<LoadSpinner/>);
                }
                return arr;   
            })()}
        </div>
        </Col>
        </>
    );

}

export default Calendario;