import React from 'react';
import MenuNav from './MenuNav';
import Footer from './Footer';
import Container from 'react-bootstrap/esm/Container';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import Image from 'react-bootstrap/esm/Image';
function Prezzi() {
    let tecnici = [
        {id: 0, name:'Piega - 10,00€', selected: false, price: 10, durata: 3},
        {id: 1, name:'Taglio e piega - 20,00€', selected: false, price: 20, durata: 5},
        {id: 2, name:'Tonalizzante - 15,00€', selected: false, price: 15, durata: 4},
        {id: 3, name:'Colore e piega - 30,00€', selected: false, price: 30, durata: 7},
        {id: 4, name:'Colore, taglio e piega - 35,00€', selected: false, price: 35, durata: 8},    
        {id: 5, name:'Colore, mèches e piega - 50,00€', selected: false, price: 50, durata: 8},
        {id: 6, name:'Mèches e piega - 40,00€', selected: false, price:40, durata: 7},
        {id: 7, name:'Balayage e piega - 40,00€', selected: false, price: 40, durata: 10},
        {id: 8, name:'Sostegno riccio e piega - 60,00€', selected: false, price: 60, durata: 10},
        {id: 9, name:'Extension alla cheratina - 300,00€', selected: false, price: 300, durata: 4},
        {id: 10, name:'Trattamento cheratina lisciante - 80,00€', selected: false, price: 80, durata: 7},
        {id: 11, name:'Baffetti - 3,00€', selected: false, price: 3, durata: 1},
        {id: 12, name:'Sopracciglia - 3,00€', selected: false, price: 3, durata: 1},
        {id: 13, name:'Ceretta braccia - 3,00€', selected: false, price: 3, durata: 2},
        {id: 14, name:'Make up - da 30,00€', selected: false, price: 30, durata: 2}
    ];
    let trattamenti = [
        {id: 15, name:'Cheratina - da 5,00€', selected: false, price: 5, durata: 4},
        {id: 16, name:'Acido Ialuronico - da 5,00€', selected: false, price: 5, durata: 4},
        {id: 17, name:'Idratante - da 5,00€', selected: false, price: 5, durata: 4},
        {id: 18, name:'Anti crespo - da 5,00€', selected: false, price: 5, durata: 4},
        {id: 19, name:'Anti sebo - da 5,00€', selected: false, price: 5, durata: 4},
        {id: 20, name:'Anti forfora - da 5,00€', selected: false, price: 5, durata: 4},
        {id: 21, name:'Anti caduta - da 5,00€', selected: false, price: 5, durata: 4},
    ];
   /* let barberia = [
        {id: 0, name:'Taglio tradizionale - 15,00€', selected: false, price: 15, durata: 2},
        {id: 1, name:'Razor Fade - 20,00€', selected: false, price: 20, durata: 2},
        {id: 2, name:'Low Fade - 20,00€', selected: false, price: 20, durata: 2},
        {id: 3, name:'Mid Fade - 20,00€', selected: false, price: 20, durata: 2},
        {id: 4, name:'High Fade - 20,00€', selected: false, price: 20, durata: 2},
        {id: 5, name:'Buzz Cut - 20,00€', selected: false, price: 20, durata: 2},
        {id: 6, name:'Spike Low Fade - 20,00€', selected: false, price: 20, durata: 2},
        {id: 7, name:'French Crop - 20,00€', selected: false, price: 20, durata: 2},
        {id: 8, name:'Taper Fade - 20,00€', selected: false, price: 20, durata: 2},
        {id: 9, name:'Barba tradizionale - 5,00€', selected: false, price: 5, durata: 1},
        {id: 10, name:'Sagomatura Barba - 7,00€', selected: false, price: 7, durata: 1},
        {id: 11, name:'Barba Luxury - 10,00€', selected: false, price: 10, durata: 1},
        {id: 12, name:'Hair Tatoo - 10,00€', selected: false, price: 10, durata: 1},
        {id: 13, name:'Sopracciglia - 3,00€', selected: false, price: 3, durata: 1}
    ];*/
    /*let estetica = [
        {id: 0, name:'Pedicure Estetica - 15,00€', selected: false, price: 15, durata: 2},
        {id: 1, name:'Semi permanente classico - 10,00€', selected: false, price: 10, durata: 2},
        {id: 2, name:'Semi permanente rinforzato - 15,00€', selected: false, price: 15, durata: 2},
        {id: 3, name:'Ricostruzione - 30,00€', selected: false, price: 30, durata: 2},
        {id: 4, name:'Disegni extra - da 5,00€', selected: false, price: 5, durata: 2}
    ];*/
    return(
        <>
            <MenuNav active={{path: '/listinoprezzi'}}/>
            <div style={{background: 'black'}}>
            <Container style={{paddingTop: '2rem', paddingBottom: '2rem'}}>
                <Row style={{marginBottom: '2rem', marginTop: '2rem'}} className="shadowonly">
                    <Col style={{textAlign: 'left', padding: '1rem'}} md={{span: 6, order: 'first'}} xs={{order: 'last'}}>
                        <h1 style={{fontSize: '26px'}}>Lavori Tecnici</h1>
                        <ul style={{paddingLeft: '0'}}>
                            {tecnici.map((item) => {
                                return <li style={{listStyle: 'none'}} key={item.id}><p>{item.name}</p></li>
                            })}
                        </ul>
                    </Col>
                    <Col className="d-flex justify-content-center align-item-center" style={{borderColor: 'rgba(250, 239, 240, 0.6)', borderLeft: '1px solid', padding: '1rem'}} md={{span: 6, order: 'last'}} xs={{order: 'first'}}>
                        {/*<Image
                            style={{margin: '0 auto'}}
                            src="./beautyhairbanner.png"
                            width="100%"
                            height="100%"
                            rounded
                            //fluid
                            alt="Hair Beauty di Giada Messina a Palermo Logo"
                        />*/}
                    </Col>
                </Row>
                <Row style={{marginBottom: '2rem', marginTop: '2rem'}} className="shadowonly">
                    <Col className="d-flex justify-content-center align-item-center" style={{borderColor: 'rgba(250, 239, 240, 0.6)', borderRight: '1px solid', padding: '1rem'}} md={{span: 6, order: 'first'}} xs={{order: 'first'}}>
                        {/*<Image
                            style={{margin: '0 auto'}}
                            src="./beautyhairbanner.png"
                            width="100%"
                            height="100%"
                            rounded
                            alt="Hair Beauty di Giada Messina a Palermo Logo"
                        />*/}
                    </Col>            
                    <Col style={{textAlign: 'right', padding: '1rem'}} md={{span:6, order: 'last'}} xs={{order: 'last'}}>
                        <h1 style={{fontSize: '26px'}}>Trattamenti</h1>
                        <ul style={{paddingLeft: '0'}}>
                            {trattamenti.map((item) => {
                                return <li style={{listStyle: 'none'}} key={item.id}><p>{item.name}</p></li>
                            })}
                        </ul>
                    </Col>
                </Row>
                {/*<Row style={{marginBottom: '2rem', marginTop: '2rem'}} className="shadowonly">
                    <Col style={{textAlign: 'left', padding: '1rem'}} md={{span: 6, order: 'first'}} xs={{order: 'last'}}>
                        <h1 style={{fontSize: '26px'}}>Barberia</h1>
                        <ul style={{paddingLeft: '0'}}>
                            {barberia.map((item) => {
                                return <li style={{listStyle: 'none'}} key={item.id}><p>{item.name}</p></li>
                            })}
                        </ul>
                    </Col>
                    <Col className="d-flex justify-content-center align-item-center" style={{borderColor: 'rgba(250, 239, 240, 0.6)', borderLeft: '1px solid', padding: '1rem'}} md={{span: 6, order: 'last'}} xs={{order: 'first'}}>
                        {/*<Image
                            style={{margin: '0 auto'}}
                            src="./beautyhairbanner.png"
                            width="100%"
                            height="100%"
                            rounded
                            //fluid
                            alt="Hair Beauty di Giada Messina a Palermo Logo"
                        />}
                    </Col>
                </Row>*/}
                {/*<Row style={{marginBottom: '2rem', marginTop: '2rem'}} className="shadowonly">
                    <Col className="d-flex justify-content-center align-item-center" style={{borderColor: 'rgba(250, 239, 240, 0.6)', borderRight: '1px solid', padding: '1rem'}} md={{span: 6, order: 'first'}} xs={{order: 'first'}}>
                        {/*<Image
                            style={{margin: '0 auto'}}
                            src="./beautyhairbanner.png"
                            width="100%"
                            height="100%"
                            rounded
                            alt="Hair Beauty di Giada Messina a Palermo Logo"
                        />
                    </Col>            
                    <Col style={{textAlign: 'right', padding: '1rem'}} md={{span:6, order: 'last'}} xs={{order: 'last'}}>
                        <h1 style={{fontSize: '26px'}}>Estetica</h1>
                        <ul style={{paddingLeft: '0'}}>
                            {estetica.map((item) => {
                                return <li style={{listStyle: 'none'}} key={item.id}><p>{item.name}</p></li>
                            })}
                        </ul>
                    </Col>
                        </Row>*/}
            </Container>
            </div>
            <Footer/>
        </>
    );
}
export default Prezzi