import React, {useEffect} from 'react';
import { GoogleMap, useJsApiLoader, MarkerF } from '@react-google-maps/api';
import MenuNav from './MenuNav';
import Footer from './Footer';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { GeoAltFill, PhoneFill, EnvelopePlusFill } from 'react-bootstrap-icons';
import { Helmet } from 'react-helmet';

const containerStyle = {
  width: '100%',
  height: '400px'
};

const center = {
  lat: 38.136517,
  lng: 13.345178
};
const zoom = 15;
function Contatti({socket}) {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyAlwoitsGXRtdbV6yzSLiWeLitCkT-qoZg"
  })
  useEffect(() => {
        socket?.emit('enterContatti');
    }, [socket])
  const [map, setMap] = React.useState(null)

  const onLoad = React.useCallback(function callback(map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    map.setZoom(zoom)
    setMap(map)
  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  return isLoaded ? (
    <>
        <Helmet>
            <title>Contatti - Salone di Parrucchieria - Beauty Hair Palermo</title>
            <meta name="description" content="Beauty Hair, salone di Parrucchieria a Palermo in via Giovanni Prati 32/34. Prenota ora il tuo servizio, aperti dal martedì al sabato. Per info: 3477209011"/>
            <meta property="og:title" content="Contatti Salone di Parrucchieria - Beauty Hair Palermo" />
            <meta property="og:type" content="website" />
            <meta property="og:url" content="https://beautyhairpalermo.it/contatti"/>
            <meta property="og:image" content="https://beautyhairpalermo.it/beautyhairmaps.jpeg" />    
        </Helmet>
        <MenuNav active={{path: '/contatti'}}/>
        <div className='sfondo'>

        </div>
                <Col xl={12}>
                    <div className="infoContatti text-center">
                        <h1>Contatti</h1>
                    </div>
                </Col>
                <Col className="d-sm-flex d-block">
                    <div className="contactInfo">
                        <h2><PhoneFill/></h2>
                        <p><b><a className="link" href="tel:393203229954">+39 320 322 99 54</a></b></p>
                    </div>
                    <div className="contactInfo">
                        <h2><EnvelopePlusFill/></h2>
                        <p><b><a className="link" href="mailto:info@beautyhairpalermo.it">info@beautyhairpalermo.it</a></b></p>
                    </div>
                    <div className="contactInfo">
                        <h2><GeoAltFill/></h2>
                        <p><b><a className="link" href="https://maps.app.goo.gl/feSkVHfBGAqFNGvs5">Via Giovanni Prati 32-34, Palermo</a></b></p>
                    </div>

                </Col>
        <Container>
            <Row>
                <Col>
                    <div className='text-center'>
                        <h1>Dove ci troviamo?</h1>
                    </div>
                    <GoogleMap
                        mapContainerStyle={containerStyle}
                        center={center}
                        zoom={zoom}
                        onLoad={onLoad}
                        onUnmount={onUnmount}
                    >
                        {
                            <MarkerF
                                label={{text: 'Beauty Hair di Giada Messina', className:'Marker'}}
                                position={center}
                            />
                        }
                        <></>
                    </GoogleMap>
                </Col>
            </Row>
        </Container>

        <Footer/>
    </>
  ) : <></>
}

export default Contatti;