import { React, useState, useEffect, useRef } from 'react'; 
import Form from 'react-bootstrap/Form'
import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { Calendar2X } from 'react-bootstrap-icons';
import Errore from './Errore';
import MenuNav from './MenuNav';
import Footer from './Footer';
import Confirm from './Confirm';
import axios from 'axios';

function Disdetta({socket}) {
    const myRef = useRef(null)
    const executeScroll = () => myRef.current.scrollIntoView()    
    const [confirm, showConfirm] = useState(false);
    const [error, showError] = useState(false);
    const [typerror, setError] = useState({id: null})
    const [nome, setNome] = useState("");
    const [cognome, setCognome] = useState("");
    const [cellulare, setCellulare] = useState("");
    const [email, setEmail] = useState("");
    const [selectedDay, setSelectedDay] = useState(false);
    const [selectedDate, setSelectedDate] = useState(false);
    const [selectedOra, setSelectedOra] = useState(false);
    const [listDate, setListDate] = useState([]);
    const orestandard = [
        {id: 0, hour: '08:30'},
        {id: 1, hour: '08:45'},
        {id: 2, hour: '09:00'},
        {id: 3, hour: '09:15'},
        {id: 4, hour: '09:30'},
        {id: 5, hour: '09:45'},
        {id: 6, hour: '10:00'},
        {id: 7, hour: '10:15'},
        {id: 8, hour: '10:30'},
        {id: 9, hour: '10:45'},
        {id: 10, hour: '11:00'},
        {id: 11, hour: '11:15'},
        {id: 12, hour: '11:30'},
        {id: 13, hour: '11:45'},
        {id: 14, hour: '12:00'},
        {id: 15, hour: '12:15'},
        {id: 16, hour: '12:30'},
        {id: 17, hour: '12:45'},
        {id: 18, hour: '13:00'},
        {id: 19, hour: '13:15'},
        {id: 20, hour: '13:30'},
        {id: 21, hour: '13:45'},
        {id: 22, hour: '14:00'},
        {id: 23, hour: '14:15'},
        {id: 24, hour: '14:30'},
        {id: 25, hour: '14:45'},
        {id: 26, hour: '15:00'},
        {id: 27, hour: '15:15'},
        {id: 28, hour: '15:30'},
        {id: 29, hour: '15:45'},
        {id: 30, hour: '16:00'},
        {id: 31, hour: '16:15'},
        {id: 32, hour: '16:30'},
        {id: 33, hour: '16:45'},
        {id: 34, hour: '17:00'},
        {id: 35, hour: '17:15'},
        {id: 36, hour: '17:30'},
        {id: 37, hour: '17:45'},
        {id: 38, hour: '18:00'},
        {id: 39, hour: '18:15'},

    ];
    useEffect(() => {
        socket?.emit('enterDisdetta');
    }, [socket])
    const reHandleDate = (value) => {
        const date = value.split('-');
        const finaldate = date[2]+'-'+date[1]+'-'+date[0];
        return finaldate;
    }
    const checkDateHour = (valdate, valora) => {
        //console.log('valdate', valdate, 'valora', valora)
        const datanuova = new Date();
        const datafromval = new Date(valdate);
       //console.log('datafromval', datafromval)
        const orasplit = valora.split(':');
        datafromval.setHours(orasplit[0], orasplit[1], 0, 0);
        //console.log(datafromval, 'datafromval', 'datanuova', datanuova)
        if(datafromval > datanuova)
            return true;
        else 
            return false;
    }
    const handleClick = async() => {
        if(nome.length >= 2 && validateName(nome)) {
            if(cognome.length >=2 && validateName(cognome)) {
                if(String(cellulare).length === 10 && !isNaN(parseInt(cellulare))) {
                    if(checkEmail(email)) {
                        if(selectedDay !== false) {
                            if(selectedDate !== false) {
                                if(selectedOra !== false) {
                                    if(checkDateHour(reHandleDate(selectedDate), selectedOra) !== false) {
                                        const newDate = reHandleDate(selectedDate);
                                        const params = [{
                                            data:newDate, ora: selectedOra, nome: nome, cognome: cognome, cell: cellulare, mail: email, giorno: selectedDay
                                        }];
                                        showError(true);
                                        await axios.post('https://beautyhairpalermo.it:3001/deletepren',{params})
                                        .then(res=> {
                                            if(typeof res.data.error === 'undefined') {
                                                if (typeof window !== 'undefined') {
                                                    showConfirm(true);
                                                    setTimeout(() => {window.location.href="/"},3500);
                                                    executeScroll();
                                                }
                                            } else {
                                                showError(true);
                                                setError({id: 7});
                                                setTimeout(() => {
                                                    showError(false);
                                                    setError({id: null});
                                                }, 2000)
                                            }
                                        })
                                    } else {
                                        setError({ id: 11});
                                        showError(!error);
                                        setTimeout(() => {
                                            setError({ id: null });
                                            showError(false);
                                        }, 2000);
                                    }
                                } else {
                                    setError({ id: 10 });
                                    showError(!error);
                                    setTimeout(() => {
                                        setError({ id: null });
                                        showError(false);
                                    }, 2000);
                                }
                            } else {
                                setError({ id: 9 });
                                showError(!error);
                                setTimeout(() => {
                                    setError({ id: null });
                                    showError(false);
                                }, 2000);
                            }
                        } else {
                            setError({ id: 8 });
                            showError(!error);
                            setTimeout(() => {
                                setError({ id: null });
                                showError(false);
                            }, 2000);
                        }
                    } else {
                        setError({ id: 3 });
                        showError(!error);
                        setTimeout(() => {
                            setError({ id: null });
                            showError(false);
                        }, 2000);
                    }
                } else {
                    setError({ id: 2 });
                    showError(!error);
                    setTimeout(() => {
                        setError({ id: null });
                        showError(false);
                    }, 2000);
                }
            } else {
                setError({ id: 1 });
                showError(!error);
                setTimeout(() => {
                    setError({ id: null });
                    showError(false);
                }, 2000);
            }
        } else {
            setError({ id: 0 });
            showError(!error);
            setTimeout(() => {
                setError({ id: null });
                showError(false);
            }, 2000);
        }
    }
    const checkEmail = (email) => {
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if ( re.test(email) ) {
            return true;
        }
        else {
            return false;
        }
    }
    function validateName(name) {
        var isValidName = true;
        if(/[!@#$%^&*(),.?":{}|<>]/g.test(name) ||/* !/^[A-Z]/.test(name) ||*/ /\d+/g.test(name)) {
          isValidName = false;
        }
        return isValidName;
    }
   // const [infoUtente, setInfoUtente] = useState(null);
    const [infoForm, setInfoForm] = useState(
        [
            {id: 'nome', active: false},
            {id: 'cognome', active: false},
            {id: 'email', active: false},
            {id: 'cellulare', active: false},
            {id: 'day', active: false},
            {id: 'date', active: false},
            {id: 'ora', active: false}
        ]
    );
    const getVal = (codice) => {
        let val = false;
        infoForm.map((item) => {
            if(item.id === codice) {
                return val = item.active;
            }
            return item;
        });
        return val;
    }
    const checkForm = (codice, val) => {
        //console.log(codice, val);
        let newArr = '';
        switch(codice)
        {
            case 'nome': {
                newArr = infoForm.map((item, i) => {
                    if(item.id === codice) {
                        if(val.length >= 2 && validateName(val)) {
                          //  console.log('entr');
                            return {...item, active: true};
                        } else {
                          //  console.log('entro');
                            return {...item, active: false};
                        }
                    }
                    return item;
                });
                break;
            }
            case 'cognome': {
                newArr = infoForm.map((item, i) => {
                    if(item.id === codice) {
                        if(val.length >= 2 && validateName(val)) {
                            return {...item, active: true};
                        } else {
                            return {...item, active: false};
                        }
                    }
                    return item;
                });
                break;
            }
            case 'cellulare': {
                newArr = infoForm.map((item, i) => {
                    if(item.id === codice) {
                        if(val.length === 10 && !isNaN(val)) {
                            return {...item, active: true};
                        } else {
                            return {...item, active: false};
                        }
                    }
                    return item;
                });
                break;
            }
            case 'email': {
                newArr = infoForm.map((item, i) => {
                    if(item.id === codice) {
                        if(checkEmail(val)) {
                            return {...item, active: true};
                        } else {
                            return {...item, active: false};
                        }
                    }
                    return item;
                });
                break;
            }
            case 'day':
            case 'date':
            case 'ora': {
                newArr = infoForm.map((item, i) => {
                    if(item.id === codice) {
                        if(val !== null) {
                            return {...item, active: true};
                        } else {
                            return {...item, active: false};
                        }
                    }
                    return item;
                });
                break;
                break;
            }
            default: return('');
        }
       // console.log(newArr);
        setInfoForm(newArr);
    }
    const createListDate = (value) => {
        const dataoggi = new Date();
        let i  = 0;
        const arrDate = [];
        const numofDays = ((7 - dataoggi.getDay()) % 7 + 1*value) % 7;
        const newDate = dataoggi.getDate() + numofDays;
        dataoggi.setDate(newDate);
        while(i < 10) {
            if(i > 0)
                dataoggi.setDate(dataoggi.getDate()+7);
            const currentYear = dataoggi.getFullYear();
            const currentMonth = dataoggi.getMonth()+1;
            const day = dataoggi.getDate();
            const datafinale = (day < 10 ? '0'+day : day)+'-'+(currentMonth < 10 ? '0'+currentMonth : currentMonth)+'-'+currentYear;
            arrDate.push(datafinale)
            i++;
        }
        setListDate(arrDate);
        return arrDate;
    }
    const handleChange = ({ target }) => {
  //      console.log('target', target);
        const { id, value } = target
        switch(id) {
            case 'nome': {
                setNome(value);
                break;
            }
            case 'cognome': {
                setCognome(value);
                break;
            }
            case 'email': {
                setEmail(value);
                break;
            }
            case 'cellulare': {
                setCellulare(value);
                break;
            }
            case 'day': {
                setSelectedDay(value);
                setSelectedDate(false);
                setSelectedOra(false);
                createListDate(value);
                break;
            }
            case 'date': {
                setSelectedDate(value);
                setSelectedOra(false);
              //  createListOre();
                break;
            }
            case 'ora': {
                setSelectedOra(value);
                break;
            }
            default: return false;
        }
       // setInfoUtente(newData)
       // cookies.set(id, value)
        //console.log(infoUtente)
        checkForm(id, value);
        //renderState(newData);
    }
    return(
        <>
        <MenuNav active={{path: '/disdetta'}}/>
        <div className="justify-content-center align-items-center" style={{ paddingTop: '3em', paddingBottom: '3em', background: '#000'}}>
        <Container className="widthAuto"> 
            <Row>
                <Col xs={12}>
                    <h1>Disdetta</h1>
                    <p>Inserisci i tuoi dati per disdire il tuo appuntamento.</p>
                    <Form.Control
                        key="nomepersona"
                        type="text"
                        id="nome"
                        placeholder="Nome..."
                        //value={cookies.get('nome')}
                        onChange={handleChange}
                        className={`${getVal('nome') ? 'correct' : ''}`}
                    />
                    <Form.Control
                        key="cognomepersona"
                        style= {{marginTop: '1em'}}
                        type="text"
                        id="cognome"
                        placeholder="Cognome..."
                        //value={cookies.get('cognome')}
                        onChange={handleChange}
                        className={`${getVal('cognome') ? 'correct' : ''}`}
                    />
                    <Form.Control
                        key="cellularepersona"
                        style= {{marginTop: '1em'}}
                        type="phone"
                        id="cellulare"
                        placeholder="Cellulare..."
                        onChange={handleChange}
                        className={`${getVal('cellulare') ? 'correct' : ''}`}
                    />           
                    <Form.Control
                        key="emailpersona"
                        style= {{marginTop: '1em'}}
                        type="email"
                        id="email"
                        placeholder="Email..."
                        onChange={handleChange}
                        className={`${getVal('email') ? 'correct' : ''}`}
                    />
                    <Form.Select key="dayofweek" id="day" style= {{marginTop: '1em'}} onChange={handleChange} className={`${getVal('day') ? 'correct' : ''}`}>
                        <option selected disabled hidden>Seleziona giorno...</option>
                        <option value="1">Lunedì</option>
                        <option value="2">Martedì</option>
                        <option value="3">Mercoledì</option>
                        <option value="4">Giovedì</option>
                        <option value="5">Venerdì</option>
                        <option value="6">Sabato</option>
                        <option value="0">Domenica</option>                        
                    </Form.Select>
                    <Form.Select key="dateofpoint" id="date" disabled = {selectedDay ? false : true} onChange={handleChange} style= {{marginTop: '1em'}} className={`${getVal('date') ? 'correct' : ''}`}>
                        <option selected disabled hidden>Seleziona data...</option>
                        {listDate.map((item) => {
                            return (<option value={item}>{item}</option>)
                        })}                    
                    </Form.Select>
                    <Form.Select key="oraofpoint" id="ora" disabled = {selectedDate ? false : true} onChange={handleChange} style= {{marginTop: '1em'}} className={`${getVal('ora') ? 'correct' : ''}`}>
                        <option selected disabled hidden>Seleziona ora...</option>
                        {orestandard.map((item) => {
                            return (<option value={item.hour}>{item.hour}</option>)
                        })}                   
                    </Form.Select>
                </Col>
                <Col style={{textAlign: 'left'}}>
                        { error ? <Errore error={typerror}/> : null}
                </Col>
                <Col style={{textAlign: 'right'}}>
                    {confirm ? null : <Button style = {{marginTop: '1em', marginBottom: '2em'}} onClick = {handleClick}  disabled={error} variant="light"><Calendar2X/> Disdetta</Button>}
                </Col>
                <Col ref={myRef} style={confirm ? {minHeight: '100vh', marginTop : '5em'} : null} xs={12}>
                    {(() => {
                        if(confirm === true)
                            return(<Confirm text={{val: "La disdetta del tuo appuntamento è avvenuta con successo."}}/>)
                        else
                            return (null)
                    })()}
                </Col>
            </Row>
        </Container>
        </div>
        <Footer/>
    </>
    );
}


export default Disdetta;