import React, {useEffect, useState} from 'react';
import {BrowserRouter, Routes, Route} from 'react-router-dom'
import Home from './Home';
import Prezzi from './Prezzi';
import PrenotaPage from './PrenotaPage'
import Disdetta from './Disdetta'
import Contatti from './Contatti'
import Result from './Result'
import 'bootstrap/dist/css/bootstrap.min.css';
//import Axios from "axios";
import {io} from 'socket.io-client';

function App() {
  const [socket, setSocket] = useState(null);
  /*Axios({
    method: "GET",
    url: "http://localhost:3001/",
    headers: {
      "Content-Type": "application/json"
    }
  }).then(res => {
    console.log(res.data.message);
  });*/
  useEffect(() => {
    const getSocket = async() => {
      setSocket(await io("https://beautyhairpalermo.it:3001/"))
    }
    getSocket();
  },[])
  useEffect(() => {
      socket?.emit('join');
  },[socket])
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home socket = {socket}/>/*<Navigate to="/prenota"/>*/}></Route>
        <Route path="/listinoprezzi" element={<Prezzi/>}></Route>
        <Route path="/prenota" element={<PrenotaPage socket = {socket}/>}></Route>
        <Route path="/disdetta" element={<Disdetta socket = {socket}/>}></Route>
        <Route path="/contatti" element={<Contatti socket = {socket}/>}></Route>
        <Route path="/result" element={<Result/>}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
