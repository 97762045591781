import React, { useEffect } from "react";
import CookieConsent, { Cookies } from "react-cookie-consent";
export const IubendaPrivacy = ({
    policyId = 65409498
}) => {
    useEffect(() => {
        var s = document.createElement("script");
        let tag = document.getElementsByTagName("script")[0];

        s.src="https://cdn.iubenda.com/iubenda.js";

        tag.parentNode.insertBefore(s,tag);
    }, []);

    return <a href={`https://www.iubenda.com/privacy-policy/${policyId}`} class="iubenda-white iubenda-embed" title="Privacy Policy ">Privacy Policy</a>
};
export const IubendaCookie = ({
    policyId = 65409498
}) => {
    useEffect(() => {
        var s = document.createElement("script");
        let tag = document.getElementsByTagName("script")[0];

        s.src="https://cdn.iubenda.com/iubenda.js";

        tag.parentNode.insertBefore(s,tag);
    }, []);

    return <a href={`https://www.iubenda.com/privacy-policy/${policyId}/cookie-policy`} class="iubenda-white iubenda-embed" title="Cookie Policy ">Cookie Policy</a>
};
export const Consent = () => {
    return(
        <CookieConsent
            location="bottom"
            buttonText="Accetta"
            cookieName="CookieAccept"
            style={{ background: "#2B373B" }}
            buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
            expires={150}
        >
            Questo sito utilizza solamente dei cookie tecnici per il suo normale funzionamento.{" "}
        </CookieConsent>
    )
}