import React, { useState, useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { ArrowRight } from 'react-bootstrap-icons';
import axios from 'axios';
import Errore from './Errore';

function Riepilogo({updateIndex, error, typeError, data, ora, operatore, nome, cognome, cellulare, email, note, servizi, updateState}) {
    const [operatorename, setOperatore] = useState([]);
    const [errore, setErrore] = useState(error);
    const [typeerror, setTypeError] = useState(typeError);
    const renderState = () => {
        updateState();
    }
    const renderIndex = () => {
        setTimeout(() => {
            updateIndex(1)
        },2000);
    }  
    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        const getOperatore = async() => {
            let oper = operatore;
            const params = {
                id: oper
            }
            await axios.get('https://beautyhairpalermo.it:3001/getnomeoperatore', {
                params
            })
            .then(res=> { if(res.data.length) setOperatore(res.data[0].Nome) })
            .catch(error => console.log(error))
        }
        getOperatore();
    },[])
    const getDay = (value) => {
        let val = "Errore";
        switch(value) {
            case 0: {
                val = "Domenica"
                break;
            }
            case 1: {
                val = "Lunedì"
                break;
            }
            case 2: {
                val = "Martedì"
                break;
            }
            case 3: {
                val = "Mercoledì"
                break;
            }
            case 4: {
                val = "Giovedì"
                break;
            }
            case 5: {
                val = "Venerdì"
                break;
            }
            case 6: {
                val = "Sabato"
                break;
            }
            default: {
                val = "Errore"
                break;
            }
        }
        return val;
    }    
    return (
        <>
        <Col xs={12} className="justify-content-center align-items-center">
            <Card style={{marginBottom: '1em'}} className="cardresult boxshadow">
                <Card.Title>
                    Riepilogo prenotazione
                </Card.Title>
                <Card.Body>
                    <p style={{color: 'black'}}>Data: <b>{data} {ora}</b></p>
                    <p style={{color: 'black'}}>Nome: <b>{nome}</b></p>
                    <p style={{color: 'black'}}>Cognome: <b>{cognome}</b></p>
                    <p style={{color: 'black'}}>Cellulare: <b>{cellulare}</b></p>
                    <p style={{color: 'black'}}>Email: <b>{email}</b></p>
                    <p style={{color: 'black'}}>Operatore: <b>{operatorename}</b></p>
                    <p style={{color: 'black'}}>Note: <b>{note}</b></p>
                    <p style={{color: 'black'}}>Servizi: <b>{servizi}</b></p>          
                </Card.Body>
            </Card>
        </Col>
        <Col style={{textAlign: 'left'}}>
            { errore ? <Errore error={typeerror}/> : null}

        </Col>
        <Col style={{textAlign: 'right'}}>
            <Button style = {{marginTop: '1em', marginBottom: '1em'}}  disabled={errore} onClick = {renderState}  variant="light">Conferma prenotazione <ArrowRight/></Button>
        </Col>
        {(() => {
            if(typeof(typeerror !== "null") && typeof(typeerror !== "undefined"))
                if(typeerror.id === 6)
                    return renderIndex();
        })()}
    </>
    )
}
export default Riepilogo;