import { React, useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import MenuNav from './MenuNav';
import { ArrowRight, ArrowLeft } from 'react-bootstrap-icons';
import './App.css';
import { useSpring, useTransition, animated, useSpringRef } from 'react-spring';
import 'bootstrap/dist/css/bootstrap.min.css';
import Footer from './Footer';
import Prenota from './Prenota';
import Servizi from './Servizi';
import Riepilogo from './Riepilogo'
import Calendario from './Calendario';
import axios from 'axios';
import { Helmet } from 'react-helmet';


function PrenotaPage({socket}) {

    const [activesorario, setActiveorarios] = useState(null);
    const [activesoperator, setActiveoperators] = useState(null);
    const [activesoperatortype, setActiveoperatorstype] = useState(null);
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();
    const [tableids, setTableIDs] = useState(currentMonth);
    const [yearids, setYearIDs] = useState(currentYear);
    const [activeitems, setActiveitems] = useState(null);
    const [index, set] = useState(0)
    const [nome, setNome] = useState('');
    const [cognome, setCognome] = useState('');
    const [email, setEmail] = useState('');
    const [cellulare, setCellulare] = useState('');
    const [note, setNote] = useState('');
    const [servizi, setServizi] = useState('');
    const [prezzo, setPrezzo] = useState(0);
    const [errore, setErrore] = useState(false);
    const [typerror, setTypeError] = useState({
        id: null
    });
    const uploadInfoUtente = (vnome, vcognome, vemail, vcellulare, vnote) => {
        setNome(vnome.trim());
        setCognome(vcognome.trim());
        setEmail(vemail.trim());
        setCellulare(vcellulare.trim());
        setNote(vnote.trim());
    }
    const updateIndex = (val) => {
        set(val);
    }
    const renderServizi = (val) => {
        setServizi(val);
    }
    const renderPrezzo = (val) => {
        setPrezzo(val);
    }
    const getNewDataDMY = () => {
        return (activeitems < 10 ? '0'+activeitems : activeitems)+'-'+(tableids < 10 ? '0'+(tableids+1) : (tableids+1))+'-'+yearids;
    }
    const getNewData = () => {
        return yearids+'-'+(tableids < 10 ? '0'+(tableids+1) : (tableids+1))+'-'+(activeitems < 10 ? '0'+activeitems : activeitems)
    }  
    const sendPren = async () => {
        let newdata = getNewData();
        let date = new Date(newdata);
        let dayofweek = date.getDay();
        const params = [
            newdata, dayofweek, activesorario, activesoperator, nome, cognome, cellulare, email, note, servizi, prezzo, 0
        ];
        await axios.post('https://beautyhairpalermo.it:3001/sendpren', {params})
        .then(res=> {
            if(typeof res.data.error === 'undefined') {
                if (typeof window !== 'undefined') {
                    window.location.href = "/result?id="+res.data[0].ID+"&key="+res.data[0].KeyPrivata;
                }
            } else {
                setErrore(true);
                setTypeError({id: 6});
                setTimeout(() => {
                    setErrore(false);
                    setTypeError({id: null});
                }, 2000)
            }
        })
        .catch(error => {
            setErrore(true);
            setTypeError({id: 6});
            setTimeout(() => {
                setErrore(false);
                setTypeError({id: null});
            }, 2000)
        })
    }
    const handleTableid = (val) => {
        setTableIDs(val);
    }
    const handleYearid = (val) => {
        setYearIDs(val);
    }
    const handleActiveitem = (val) => {
        setActiveitems(val);
    }
    const handleActiveoperator = (val) => {
        setActiveoperators(val);
    }
    const handleActiveoperatortype = (val) => {
        setActiveoperatorstype(val);
    }
    const handleActiveorario = (val) => {
        setActiveorarios(val);
    }

    const onClick = () => set(state => (state + 1) % 5) 
    const backPage = () => set(state => (state - 1) % 5)
    const pages = [
        
        ({ style }) => <animated.div className="align-items-center justify-content-center text-center d-flex" style={{ ...style, background: '#000000', minHeight: '100vh' }}>
            <a href="#" className={"Prenota"} onClick={onClick}>  
                <h1 style={{fade, fontSize: '50px'}}>Prenota ora <ArrowRight/></h1>
            </a>        
        </animated.div>,
        ({ style }) => <animated.div style={{ ...style, paddingTop: '3em', paddingBottom: '3em', background: '#000' }}>
            <Container>
                <Row>
                    <Calendario updateTableid = {handleTableid} updateYearid = {handleYearid} updateActiveitem = {handleActiveitem} updateActiveoperator = {handleActiveoperator}  updateActiveorario = {handleActiveorario} updateState = {onClick} updateActiveoperatortype={handleActiveoperatortype}/>
                </Row>
            </Container>
        </animated.div>,
        ({ style }) => 
            <animated.div className={`${index == 2 ? "appear" : ""}`}style={{ ...style, background: '#000000' }}>
                <>
                <Container>
                    <Row>
                        <Col key="colonnaportante">
                            <Button style={{marginLeft: '1em', marginTop: '1em', marginBottom: '1em'}} onClick = {backPage} variant="outline-light"><ArrowLeft/> Indietro</Button>{''}
                            <div className="divFormPrenota vh-100 ">
                                <div className="d-block widthAuto">
                                    <Col style={{textAlign: 'left'}}>
                                        <h1>Prenota</h1>
                                    </Col>
                                    <Prenota socket={socket} oldNome = {nome} oldCognome = {cognome} oldEmail = {email} oldCellulare = {cellulare} oldNote = {note} updateState={uploadInfoUtente} update={onClick}></Prenota>
                                </div> 
                            </div>
                        </Col>
                    </Row>
                </Container>
                </>
            </animated.div>,
        ({ style }) => 
            <animated.div style={{ ...style, background: '#000' }}>
                <Container>
                    <Row>
                        <Col xs="12">
                            <Button style={{marginLeft: '1em', marginTop: '1em', marginBottom: '1em'}} onClick = {backPage} variant="outline-light"><ArrowLeft/> Indietro</Button>{''}
                        </Col>
                        <Servizi updateState={onClick} updateServizi = {renderServizi} updatePrezzo = {renderPrezzo} operatortype={activesoperatortype}></Servizi>
                    </Row>
                </Container>
            </animated.div>,
        ({ style }) => 
            <animated.div style={{ ...style, background: '#000' }}>
                <Container>
                    <Row>
                        <Col xs="12">
                            <Button style={{marginLeft: '1em', marginTop: '1em', marginBottom: '1em'}} onClick = {backPage} variant="outline-light"><ArrowLeft/> Indietro</Button>{''}
                        </Col>
                        <Riepilogo updateIndex = {updateIndex} error = {errore} typeError = {typerror} updateState = {sendPren} ora = {activesorario} operatore = {activesoperator}  data = {getNewDataDMY()} nome = {nome} cognome = {cognome} cellulare = {cellulare} email = {email} note = {note} servizi = {servizi}></Riepilogo>
                    </Row>
                </Container>
            </animated.div>,
      ]
    const fade = useSpring({
        config: {
            duration: 2000
        },
        from: {
            opacity: 0
        },
        to: {
            opacity: 1
        }
    });   

    const transRef = useSpringRef();
    const transitions = useTransition(index, {
        ref: transRef,
        keys: null,
        from: { opacity: 0, transform: 'translate3d(100%,0,0)' },
        enter: { opacity: 1, transform: 'translate3d(0%,0,0)' },
        leave: { opacity: 0, transform: 'translate3d(-50%,0,0)' },
    })
    useEffect(() => {
        transRef.start()
    }, [index]);

    return (
        <>
        <Helmet>
            <title>Prenota ora - Salone di Parrucchieria - Beauty Hair Palermo</title>
            <meta name="description" content="Beauty Hair, salone di Parrucchieria a Palermo in via Giovanni Prati 32/34. Prenota ora il tuo servizio, aperti dal martedì al sabato. Per info: 3477209011"/>
            <meta property="og:title" content="Salone di Parrucchieria Prenotazione - Beauty Hair Palermo" />
            <meta property="og:type" content="website" />
            <meta property="og:url" content="https://beautyhairpalermo.it/prenota" />
            <meta property="og:image" content="https://beautyhairpalermo.it/beautyhairfavic.jpg" />    
        </Helmet>
        <div className="Principale">
           <MenuNav active={{path: '/prenota'}}></MenuNav>
           {transitions((style, i) => {
                const Page = pages[i]
                return <Page style={style} />
            })}
            <Footer></Footer>
        </div>
        </>
    );
}

export default PrenotaPage;