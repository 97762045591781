import { React, useState, useEffect } from 'react'; 
import Form from 'react-bootstrap/Form';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { ArrowRight } from 'react-bootstrap-icons';
import Errore from './Errore';

function Prenota({socket, oldNome, oldCognome, oldEmail, oldCellulare, oldNote, oldChecked, updateState, update}) {
    const [error, showError] = useState(false);
    const [typerror, setError] = useState({
        id: null
    });
    const [trattamentodatis, setTrattamentodati] = useState(false);
    const [nome, setNome] = useState(oldNome);
    const [cognome, setCognome] = useState(oldCognome);
    const [cellulare, setCellulare] = useState(oldCellulare);
    const [email, setEmail] = useState(oldEmail);
    const [note, setNote] = useState(oldNote);
    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }, []);
    useEffect(() => {
        socket?.emit('enterPrenota');
    }, [socket])
    const handleClick = () => {

        if(nome.length >= 2 && validateName(nome)) {
            if(cognome.length >=2 && validateName(cognome)) {
                if(String(cellulare).length === 10 && !isNaN(parseInt(cellulare))) {
                    if(checkEmail(email)) {
                        if(trattamentodatis) {
                            render();
                        } else {
                            setError({ id: 4 });
                            showError(!error);
                            setTimeout(() => {
                                setError({ id: null});
                                showError(false);
                            }, 2000);
                        }
                    } else {
                        setError({ id: 3 });
                        showError(!error);
                        setTimeout(() => {
                            setError({ id: null });
                            showError(false);
                        }, 2000);
                    }
                } else {
                    setError({ id: 2 });
                    showError(!error);
                    setTimeout(() => {
                        setError({ id: null });
                        showError(false);
                    }, 2000);
                }
            } else {
                setError({ id: 1 });
                showError(!error);
                setTimeout(() => {
                    setError({ id: null });
                    showError(false);
                }, 2000);
            }
        } else {
            setError({ id: 0 });
            showError(!error);
            setTimeout(() => {
                setError({ id: null });
                showError(false);
            }, 2000);
        }
    }
    const render = () => {
        renderState();
        update();
    }
    const renderState = () => {
        updateState(nome, cognome, email, cellulare, note);
    };
    const handleForm = () => {
        setTrattamentodati(!trattamentodatis);
        //cookies.set('termini', trattamentodatis);
    }
    const checkEmail = (email) => {
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if ( re.test(email) ) {
            return true;
        }
        else {
            return false;
        }
    }
    function validateName(name) {
        var isValidName = true;
        if(/[!@#$%^&*(),.?":{}|<>]/g.test(name) ||/* !/^[A-Z]/.test(name) ||*/ /\d+/g.test(name)) {
          isValidName = false;
        }
        return isValidName;
    }
   // const [infoUtente, setInfoUtente] = useState(null);
    const [infoForm, setInfoForm] = useState(
        [
            {id: 'nome', active: false},
            {id: 'cognome', active: false},
            {id: 'email', active: false},
            {id: 'cellulare', active: false},
            {id: 'note', active: false}
        ]
    );
    const getVal = (codice) => {
        let val = false;
        infoForm.map((item) => {
            if(item.id === codice) {
                return val = item.active;
            }
            return item;
        });
        return val;
    }
    const checkForm = (codice, val) => {
        let newArr = '';
        switch(codice)
        {
            case 'nome': {
                newArr = infoForm.map((item, i) => {
                    if(item.id == codice) {
                        if(val.length >= 2 && validateName(val)) {
                            return {...item, active: true};
                        } else {
                            return {...item, active: false};
                        }
                    }
                    return item;
                });
                break;
            }
            case 'cognome': {
                newArr = infoForm.map((item, i) => {
                    if(item.id == codice) {
                        if(val.length >= 2 && validateName(val)) {
                            return {...item, active: true};
                        } else {
                            return {...item, active: false};
                        }
                    }
                    return item;
                });
                break;
            }
            case 'cellulare': {
                newArr = infoForm.map((item, i) => {
                    if(item.id == codice) {
                        if(val.length === 10 && !isNaN(val)) {
                            return {...item, active: true};
                        } else {
                            return {...item, active: false};
                        }
                    }
                    return item;
                });
                break;
            }
            case 'email': {
                newArr = infoForm.map((item, i) => {
                    if(item.id == codice) {
                        if(checkEmail(val)) {
                            return {...item, active: true};
                        } else {
                            return {...item, active: false};
                        }
                    }
                    return item;
                });
                break;
            }
            case 'note': {
                newArr = infoForm.map((item, i) => {
                    if(item.id == codice) {
                        if(val.length >=2) {
                            return {...item, active: true};
                        } else {
                            return {...item, active: false};
                        }
                    }
                    return item;
                });
                break;
            }
            default: return('');
        }
        setInfoForm(newArr);
    }
    const handleChange = ({ target }) => {
        const { id, value } = target
        switch(id) {
            case 'nome': {
                setNome(value);
                break;
            }
            case 'cognome': {
                setCognome(value);
                break;
            }
            case 'email': {
                setEmail(value);
                break;
            }
            case 'cellulare': {
                setCellulare(value);
                break;
            }
            case 'note': {
                setNote(value);
                break;
            }
        }
       // setInfoUtente(newData)
       // cookies.set(id, value)
        //console.log(infoUtente)
        checkForm(id, value);
        //renderState(newData);
    }
    return(
        <>
        <Form.Control
            key="nomepersona"
            type="text"
            id="nome"
            placeholder="Nome..."
            //value={cookies.get('nome')}
            defaultValue={oldNome}
            onChange={handleChange}
            className={`${getVal('nome') ? 'correct' : ''}`}
        />
        <Form.Control
            key="cognomepersona"
            style= {{marginTop: '1em'}}
            type="text"
            id="cognome"
            placeholder="Cognome..."
            defaultValue={oldCognome}
            //value={cookies.get('cognome')}
            onChange={handleChange}
            className={`${getVal('cognome') ? 'correct' : ''}`}
        />
        <Form.Control
            key="cellularepersona"
            style= {{marginTop: '1em'}}
            type="phone"
            id="cellulare"
            placeholder="Cellulare..."
            defaultValue={oldCellulare}
            //value={cookies.get('cellulare')}
            onChange={handleChange}
            className={`${getVal('cellulare') ? 'correct' : ''}`}
        />           
        <Form.Control
            key="emailpersona"
            style= {{marginTop: '1em'}}
            type="email"
            id="email"
            placeholder="Email..."
            defaultValue={oldEmail}
            //value={cookies.get('email')}
            onChange={handleChange}
            className={`${getVal('email') ? 'correct' : ''}`}
        />
        <Form.Control as="textarea" rows={3}
            key="notepersona"
            style= {{marginTop: '1em'}}
            id="note"
            placeholder="Note..."
            defaultValue={oldNote}
            onChange={handleChange}
            className={`${getVal('note') ? 'correct' : ''}`}
        />
        <Form.Check
            label = {<p style={{color: 'white'}}>Accetto i termini e le condizioni descritte nella <a target="_blank" href="https://www.iubenda.com/privacy-policy/65409498/">Privacy</a> & <a target="_blank" href="https://www.iubenda.com/privacy-policy/65409498/cookie-policy">Cookie Policy</a></p>}
            style= {{marginTop: '1em'}}
            id="trattamentodati"
            //checked = {cookies.get('termini')}
            onChange={handleForm}
        />
        <Col style={{textAlign: 'left'}}>
            { error ? <Errore error={typerror}/> : null}
        </Col>
        <Col style={{textAlign: 'right'}}>
            <Button style = {{marginTop: '1em', marginBottom: '2em'}} onClick = {handleClick}  disabled={error} variant="light">Servizi <ArrowRight/></Button>
        </Col>
    </>
    );
}
export default Prenota;