import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Facebook, Instagram, Whatsapp, Telephone } from 'react-bootstrap-icons';
import Image from 'react-bootstrap/Image'
import { useNavigate } from 'react-router-dom';

function MenuNav({active}) {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate('/');
  }
  return (
    <Navbar variant="dark" key='md' expand='md' style={{background: '#000000'}}>
      <Container fluid>
        <Navbar.Brand href="#">
          <Image
              style={{marginLeft: '1rem'}}
              src="./beautyhairbanner.png"
              width="250"
              height="100"
              rounded
              fluid
              onClick={handleClick}
              alt="Beauty Hair di Giada Messina a Palermo Logo"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-md`} />
            <Navbar.Offcanvas
              style={{background: '#000000'}}
              id={`offcanvasNavbar-expand-md}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-md`}
              placement="end"
            >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title style={{color: '#FFFFFF'}} id={`offcanvasNavbarLabel-expand-md`}>
                <Image
                src="./beautyhairbanner.png"
                width="150"
                rounded
                fluid
                alt="Hair Beauty di Giada Messina a Palermo Logo"
              />
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav activeKey={active.path} className="justify-content-end flex-grow-1 pe-3">
                <Nav.Link href="/">Home</Nav.Link>
                <Nav.Link href="/listinoprezzi">Listino Prezzi</Nav.Link>
                <Nav.Link href="/prenota">Prenotazione</Nav.Link>
                <Nav.Link href="/disdetta">Disdetta</Nav.Link>
                <Nav.Link href="/contatti">Contatti</Nav.Link>
                <div className='d-flex'>
                <Nav.Link className="socialIcon" rel="noreferrer" target="_blank" href="https://www.facebook.com/profile.php?id=61553279973061"><Facebook/></Nav.Link>
                <Nav.Link className="socialIcon" rel="noreferrer" target="_blank" href="https://instagram.com/beautyhairpalermo"><Instagram/></Nav.Link>
                <Nav.Link className="socialIcon" rel="noreferrer" target="_blank" href="whatsapp://send?phone=393203229954&abid=393203229954"><Whatsapp/></Nav.Link>
                <Nav.Link className="socialIcon" rel="noreferrer" target="_blank" href="tel:393203229954"><Telephone/></Nav.Link>
                </div>             
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
  );
}

export default MenuNav;